


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import { TimeStatus } from '@/_types/time-status.enum';
import EventHelper from '@/_helpers/event.helper';
import { DateTimeFormat } from '@/_types/date-time-format.enum';

@Component({
  name: 'promo-chatroom-time-check',
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
    }),
  }
})
export default class PromoChatroomTimeCheck extends Vue {

  public readonly TimeStatus: typeof TimeStatus = TimeStatus;
  public readonly event: TEvent;

  public get timeStatus(): TimeStatus {
    return EventHelper.getEventTimeStatus(this.event);
  }

  public get eventStartDateFormatted(): string {
    return (this.event && this.event.date_start) ? this.$moment(this.event.date_start).format(DateTimeFormat.SHORT_DATE) : '';
  }

}
