


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import EventChatContact from '@/_modules/event-chat/components/event-chat-contact/event-chat-contact.vue';

@Component({
  name: 'event-chat-contacts-list',
  components: { EventChatContact },
})
export default class EventChatContactsList extends Vue {

  @Prop({ type: Array, default: [] })
  public readonly contactsIds: number[];

  @Prop({ type: Error, default: null })
  public readonly contactsError: Error;

  @Prop({ type: Boolean, default: false })
  public readonly isLoading: boolean;

}
