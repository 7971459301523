


import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {IFixedDraggableChild} from '@/_types/fixed-draggable-child.interface';
import {Subject} from 'rxjs';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import Tabs from '@/_ui/tabs/tabs.vue';
import {TTab} from '@/_ui/tabs/types/tabs.type';
import {TConferenceRoom} from '@/_modules/promo/types/conference-room.type';
import {TContact} from '@/_types/contact.type';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import IconFloatingProgramPinBack
  from '@/_modules/icons/components/floating-program-card/icon-floating-program-pinback.vue';
import IconFloatingProgramToggleMinimized
  from '@/_modules/icons/components/floating-program-card/icon-floating-program-toggle-minimized.vue';
import IconFloatingProgramClose
  from '@/_modules/icons/components/floating-program-card/icon-floating-program-close.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {TFloatingProgramCard} from '@/_modules/promo/types/floating-program-card.type';
import EwDocumentList from '@/_modules/promo-hall/components/ew-document-list/ew-document-list.vue';
import {RawLocation} from 'vue-router';
import ProgramChatWrapper from '@/_modules/promo-program-new/components/program-chat-wrapper/program-chat-wrapper.vue';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import PresenceConfirmation
  from '@/_modules/promo/components/confirmations/presence-confirmation/presence-confirmation.vue';
import eventDiscoveryService, {TEventDiscoveryServiceConfig} from '@/_services/event-discovery.service';
import {takeUntil} from 'rxjs/operators';
import DateTimeHelper from '@/_helpers/date-time.helper';

enum CardTabNameKeys {
  INFO = 'title.info',
  CHAT = 'button.chat',
  QA = 'floatingProgramCard.tabs.questions',
}

@Component({
  components: {
    Tabs,
    ProgramMediaBlock,
    EwAvatar,
    EwDocumentList,
    ProgramChatWrapper,
    PresenceConfirmation,
    IconFloatingProgramPinBack,
    IconFloatingProgramToggleMinimized,
    IconFloatingProgramClose,
    iconUnFavorite,
    iconFavorite,
    iconShare,
  }
})
export default class FloatingProgramCard extends Vue implements IFixedDraggableChild {

  @Getter('promoProgramStore/conferenceRooms') conferenceRooms: TConferenceRoom[];

  @Action('promoProgramStore/removeFloatingProgram') close: (programId: number) => void;
  @Action('promoProgramStore/toggleFavorite') toggleFavorite: (params: {
    eventId: number;
    conferenceRoomId: number;
    programId: number;
    isFavorite: boolean;
  }) => Promise<void>;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string }) => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();
  private destroyed$: Subject<void> = new Subject<void>();
  public isServiceConnected: boolean = false;
  public isMinimized: boolean = true;
  public isCloseAnimationRunning: boolean = false;
  public isFavoriteLocal: boolean = false;
  public tabIndexes: { [key: string]: number } = {}; // N.B.: because we do not have some TTab.alias, and tabList is dynamic here
  public tabList: TTab[] = [
    {
      title: this.$t(CardTabNameKeys.INFO),
      isActive: true,
      index: 0,
      extraClasses: 'tab-info',
    },
  ];
  public ConferenceProgramChatType: typeof ConferenceProgramChatType = ConferenceProgramChatType;

  @Prop(Object)
  public readonly programCard: TFloatingProgramCard;

  @Watch('programCard', {immediate: true})
  public onProgramCardChange(newVal: TFloatingProgramCard): void {
    if (!newVal || !this.program) {
      return;
    }
    this.isFavoriteLocal = this.program.is_favorite;
    this.initTabList();
    this.subscribeToProgramSessionTopic();
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get program(): TConferenceProgram {
    return (this.programCard && this.programCard.program) || null;
  }

  public get programId(): number {
    return (this.program && this.program.id) || null;
  }

  public get programTitle(): string {
    return (this.program && this.program.title) || '';
  }

  public get programDescription(): string {
    return (this.program && this.program.description) || '';
  }

  public get isPresenceCheckVisible(): boolean {
    return (this.programCard && this.programCard.isPresenceCheckVisible) || false;
  }

  public initTabList(): void {

    this.tabIndexes = {};
    this.tabList = [{
      ...this.tabList[0]
    }];

    if (this.program && this.program.show_live_chat) {
      this.tabList.push({
        title: this.$t(CardTabNameKeys.CHAT),
        isActive: false,
        index: this.tabList.length,
        extraClasses: 'tab-chat',
      });
    }

    if (this.program && this.program.show_speaker_chat) {
      this.tabList.push({
        title: this.$t(CardTabNameKeys.QA),
        isActive: false,
        index: this.tabList.length,
        extraClasses: 'tab-questions',
      });
    }

    this.tabList.forEach((tab, index) => {
      tab.index = index;
      this.tabIndexes[tab.title as string] = tab.index;
    });

  }

  public get programDateYMD(): string {
    if (!this.program || !this.program.date_start) {
      return '';
    }

    const date: Date = new Date(this.program.date_start);
    return DateTimeHelper.dateToStringAsYMD(date);
  }

  public get programStandaloneRoute(): RawLocation {
    return {
      name: 'promo-program-page',
      params: {
        eventId: '' + this.eventId,
        programDate: '' + this.programDateYMD,
        programId: '' + this.program.id,
      }
    };
  }

  public get programStandaloneUrl(): string {
    return (this.program && (location.origin + this.$router.resolve(this.programStandaloneRoute).href)) || location.origin;
  }

  public get activeTabIndex(): number {
    return this.tabList.findIndex(tab => tab.isActive === true);
  }

  public get isInfoTabActive(): boolean {
    return this.activeTabIndex === this.tabIndexes[this.$t(CardTabNameKeys.INFO) as string];
  }

  public get isChatTabActive(): boolean {
    return this.activeTabIndex === this.tabIndexes[this.$t(CardTabNameKeys.CHAT) as string];
  }

  public get isQuestionsTabActive(): boolean {
    return this.activeTabIndex === this.tabIndexes[this.$t(CardTabNameKeys.QA) as string];
  }

  public get programTimeStart(): string {
    const date: Date = new Date(((this.program && this.program.date_start) || (new Date())));
    return DateTimeHelper.dateToHoursAndMinutes(date);
  }

  public get programTimeEnd(): string {
    const date: Date = new Date(((this.program && this.program.date_end) || (new Date())));
    return DateTimeHelper.dateToHoursAndMinutes(date);
  }

  public get roomOfTheProgram(): TConferenceRoom {
    if (!this.program || !this.program.conference_id || !this.conferenceRooms) {
      return null;
    }

    return this.conferenceRooms.find(room => room.id === this.program.conference_id);
  }

  public get programConfRoomTitle(): string {
    return (this.roomOfTheProgram && this.roomOfTheProgram.title) || '';
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected && this.programId) {
      this.subscribeToProgramSessionTopic();
    }
  }

  public beforeDestroy(): void {
    this.unSubscribeFromProgramSessionTopic();
  }

  public onHeaderMouseDown(event: MouseEvent): void {
    this.dragZoneMouseDown$.next(event);
  }

  public async onPinClick(): Promise<void> {
    await this.$router.push(this.programStandaloneRoute);
    this.close(this.program.id);
  }

  public onMinimizeClick(): void {
    this.isMinimized = !this.isMinimized;
  }

  public onCloseClick(): void {
    if (!this.program || this.isCloseAnimationRunning) {
      return;
    }
    this.isCloseAnimationRunning = true;
    this.isMinimized = true;
    window.setTimeout(() => {
      this.isCloseAnimationRunning = false;
      this.close(this.program.id);
    }, 250);
  }

  public onActiveTabUpdate(tab: TTab, index: number): void {
    this.tabList.forEach((item, idx) => {
      item.isActive = idx === index;
    });
  }

  public getSpeakerNameHTML(speaker: TContact): string {
    return [
      ((speaker && speaker.name) || '').trim().replace(/</g, '&lt;'),
      ((speaker && speaker.surname) || '').trim().replace(/</g, '&lt;'),
    ].join('<br />');
  }

  public onSpeakerClick(speaker: TContact): void {
    this.openContactCard({
      contactId: speaker.id,
    });
  }

  public onToggleFavoriteClick(): void {
    if (!this.program) {
      return;
    }

    this.toggleFavorite({
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: !this.isFavoriteLocal,
    });

    this.isFavoriteLocal = !this.isFavoriteLocal;
  }

  public onShareClick(event: Event): void {
    this.openSharer({
      eventTarget: (event.target as Element),
      url: this.programStandaloneUrl,
    });
  }

  public mounted(): void {
    this.subscribeToServiceEvents();
    this.delayedMaximize();
  }

  public delayedMaximize(): void {
    window.setTimeout(() => {
      this.isMinimized = false;
    }, 50);
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  public subscribeToProgramSessionTopic(): void {
    if (eventDiscoveryService.isConnected()) {
      eventDiscoveryService.subscribeToProgramSessionTopic(this.programId);
    }
  }

  public unSubscribeFromProgramSessionTopic(): void {
    eventDiscoveryService.unSubscribeFromProgramSessionTopic(this.programId);
  }

}
