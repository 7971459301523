<template>
  <svg class="svg-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="circle" d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"/>
    <path class="figure" d="M16.3818 8.35181H18.6727V10.6427" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="figure" d="M10.6434 18.6727H8.35254V16.3818" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="figure" d="M15.2393 11.8522L17.9098 9.18164" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="figure" d="M9.18262 17.9088L11.8532 15.2383" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconZoomIn = Vue.extend({
  name: 'icon-zoom-in',
});

export default IconZoomIn;
</script>

<style scoped lang="scss">
  /* stylelint-disable */

  .svg-icon {
    .circle {
      stroke: $ew-black;
    }

    .figure {
      stroke: $ew-black;
    }

    &:hover {
      .circle {
        stroke: $ew-blue;
      }

      .figure {
        stroke: $ew-blue;
      }
    }
  }
</style>
