


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import IconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';

const NOTIFICATION_SOUND = require('@/assets/sounds/intuition.ogg');

@Component({
  components: {
    IconNews,
  },
})
export default class PromoNotificationNewsArticle extends Vue {

  @Prop({ type: Object })
  public readonly notification: TDiscoveryNewsArticleNotificationResponse;

  @Prop({ type: Boolean, default: false })
  public readonly playSound: boolean;

  @Watch('notification', {immediate: true})
  public onNotificationChange(): void {
    if (this.playSound && this.notification) {
      const audio = new Audio(NOTIFICATION_SOUND);
      try { audio.play(); } catch { /* ignore */ }
    }
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public async onClick(): Promise<void> {
    await this.$router.push({
      name: 'news-issue',
      params: {
        newsIssueId: this.notification.newsId.toFixed(0),
      }
    }).catch(() => {
      /* ignore */
    });

    this.hide();
  }

  public onButtonCloseClick(): void {
    this.hide();
  }

  public hide(): void {
    this.$store.dispatch('notificationsStore/setNewsArticleNotification', null);
  }

}
