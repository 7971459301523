var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contactCard.contact)?_c('div',{ref:"contactCardRef",staticClass:"opened-contact-card",class:_vm.contactCardClassNames},[_c('div',{staticClass:"contact-card-head",on:{"click":_vm.onContactCardHeaderClick}},[(_vm.contactCard.contact)?_c('div',{staticClass:"fullname"},[_c('ew-avatar',{attrs:{"contact":_vm.contactCard.contact,"width":24,"disable-unread-count":"","disable-online-badge":""}}),_vm._v(" "+_vm._s(_vm.contactFullName)+" ")],1):_vm._e(),_c('div',{staticClass:"actions"},[_c('span',{staticClass:"action action-minimize",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMinimized($event)}}}),_c('span',{staticClass:"action action-close",on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}})])]),_c('div',{staticClass:"contact-card-body"},[_c('div',{staticClass:"contact-card-body-head"},[(_vm.isTinyMeetingRequestsBlockVisible)?_c('div',{staticClass:"tiny-meeting-requests"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('tinyMeetingRequests.title', { name: _vm.contactFirstName }))+" ")]),_vm._l((_vm.preparedContactMeetingsToMe),function(meeting){return _c('div',{key:'tiny_meeting_' + meeting.id,staticClass:"tiny-meeting"},[_c('div',{staticClass:"when"},[_vm._v(" "+_vm._s(_vm.getTinyMeetingTitle(meeting))+" "),(_vm.isMeetingOnline(meeting))?_c('span',{staticClass:"meeting-type-badge online"},[_vm._v(" "+_vm._s(_vm.$t('meetingTypes.online'))+" ")]):(_vm.isMeetingOffline(meeting))?_c('span',{staticClass:"meeting-type-badge offline"},[_vm._v(" "+_vm._s(_vm.$t('meetingTypes.offline'))+" ")]):(_vm.isMeetingExpired(meeting))?_c('span',{staticClass:"meeting-type-badge expired"},[_vm._v(" "+_vm._s(_vm.$t('meetingTypes.expired'))+" ")]):_vm._e()]),_c('div',{staticClass:"actions"},[(_vm.isConfirmMeetingActionAvailable(meeting))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('tooltips.confirmMeetingRequest'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('tooltips.confirmMeetingRequest'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}],staticClass:"action action-confirm",on:{"click":function($event){return _vm.confirmMeeting({event_id: _vm.eventId, meeting_id: meeting.id})}}},[_c('icon-tiny-confirm')],1):_vm._e(),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('tooltips.declineMeetingRequest'),
                classes: ['vue-tooltip-dark'],
                placement: 'bottom',
                delay: 0,
              }),expression:"{\n                content: $t('tooltips.declineMeetingRequest'),\n                classes: ['vue-tooltip-dark'],\n                placement: 'bottom',\n                delay: 0,\n              }"}],staticClass:"action action-decline",on:{"click":function($event){return _vm.cancelMeeting({event_id: _vm.eventId, meeting_id: meeting.id})}}},[_c('icon-tiny-decline')],1)])])})],2):_vm._e(),_c('div',{staticClass:"person-wrap"},[_c('person',{attrs:{"contact":_vm.contactCard.contact,"disable-unread-count":""}})],1),_c('tabs',{ref:"tabListWrapper",attrs:{"tab-list":_vm.tabList},on:{"active-tab":_vm.onActiveTabUpdate}})],1),(_vm.isProfileTabActive)?_c('div',{staticClass:"tab-content"},[_c('contact-tab-content-profile',{attrs:{"contact":_vm.contact}})],1):_vm._e(),(_vm.isMeetingsTabActive)?_c('div',{staticClass:"tab-content"},[(_vm.contact.id !== _vm.myself.id)?_c('contact-tab-content-meetings',{attrs:{"contact":_vm.contact}}):_vm._e()],1):_vm._e(),(_vm.isMessagesTabActive)?_c('div',{staticClass:"tab-content tab-content-messages"},[(_vm.contact.id !== _vm.myself.id)?_c('messages',{ref:"messages",attrs:{"current-contact":_vm.contact,"contact-id":_vm.contact.id}}):_vm._e()],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }