


import { Prop, Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Subject } from 'rxjs';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import IconPinBack from '@/_modules/icons/components/icon-pinback.vue';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';

@Component({
  components: {
    StreamPlayer,
    IconPinBack,
  }
})
export default class MediaBlockItem extends Vue implements IFixedDraggableChild {

  @Getter('promoStore/promoPageByExternalId') public readonly companyByExternalId: (externalId: string) => TPromoPage;
  @Getter('promoProgramStore/getProgramById') public readonly programById: (programId: number) => TConferenceProgram;

  @Prop({ type: Object })
  public readonly mediaItem: TMediaItem;

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public get mediaItemTitle(): string {
    if (this.mediaItem.entityType === 'company') {
      return this.companyTitle;
    } else if (this.mediaItem.entityType === 'program') {
      return this.programSessionTitle;
    }
    return '';
  }

  public get company(): TPromoPage {
    if (!this.mediaItem || this.mediaItem.entityType !== 'company') {
      return null;
    }
    const externalId: string = this.mediaItem.entityId as string;
    if (!externalId) {
      return null;
    }
    return this.companyByExternalId(externalId);
  }

  public get companyTitle(): string {
    if (!this.company) {
      return '';
    }
    return this.company.title;
  }

  public get program(): TConferenceProgram {
    if (!this.mediaItem || this.mediaItem.entityType !== 'program') {
      return null;
    }
    const programId: number = this.mediaItem.entityId as number;
    if (!programId) {
      return null;
    }
    return this.programById(programId);
  }

  public get programSessionTitle(): string {
    if (!this.program) {
      return '';
    }
    return this.program.title;
  }

  public onHeaderMouseDown(event: MouseEvent): void {
    this.dragZoneMouseDown$.next(event);
  }

  public onCloseClick(): void {
    if (this.mediaItem.entityType === 'company') {
      this.$store.dispatch('promoStore/pinMediaItem', {
        externalId: this.mediaItem.entityId,
        id: this.mediaItem.id,
      });
    } else if (this.mediaItem.entityType === 'program') {
      this.$store.dispatch('promoProgramStore/pinMediaItem', {
        programId: this.mediaItem.entityId,
        id: this.mediaItem.id,
      });

      this.$store.dispatch('promoProgramStore/overlookMediaItems', {
        programId: this.mediaItem.entityId,
        id: this.mediaItem.id,
      });

    }
  }

  public onPinBackClick(): void {
    if (this.mediaItem.entityType === 'company') {
      this.$router.push({
        name: 'promo-page-events-company',
        params: {
          external_id: this.mediaItem.entityId as string,
        }
      }).catch(/* ignore */);
    }
  }
}
