


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import Avatar from '@/_components/avatar/avatar.vue';
import { TContact } from '@/_types/contact.type';
import { mapGetters } from 'vuex';

@Component({
  name: 'event-chat-component',
  components: {
    Avatar
  },
  computed: {
    ...mapGetters({
      contactById: 'contactsStore/contactById',
    }),
  },
})
export default class EventChatContact extends Vue {

  @Prop({ type: Number, default: null })
  public readonly contactId: number;

  public readonly contactById: (contactId: number) => TContact;

  public get contact(): TContact {
    const contactId = this.contactId;
    return contactId ? this.contactById(contactId) : null;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactLocation(): Location {
    const contact = this.contact;
    if (!contact || !contact.id) {
      return null;
    }
    return {
      name: 'promo-page-contacts-contact',
      params: {
        eventId: '' + this.eventId,
        contact_id: '' + contact.id,
      }
    };
  }

}

