import { render, staticRenderFns } from "./meeting-decline-confirmation.html?vue&type=template&id=0024580d&scoped=true&"
import script from "./meeting-decline-confirmation.vue?vue&type=script&lang=ts&"
export * from "./meeting-decline-confirmation.vue?vue&type=script&lang=ts&"
import style0 from "./meeting-decline-confirmation.scss?vue&type=style&index=0&id=0024580d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0024580d",
  null
  
)

export default component.exports