


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';
import EventChatButton from '@/_modules/event-chat/components/event-chat-button/event-chat-button.vue';
import EventChatDialog from '@/_modules/event-chat/components/event-chat-dialog/event-chat-dialog.vue';

@Component({
  name: 'event-chat',
  components: {
    EventChatButton,
    EventChatDialog,
  },
  computed: {
    ...mapGetters({
      isChatStoreConfigured: 'chatStore/isConfigured',
    }),
  }
})
export default class EventChat extends Vue {

  public readonly isChatStoreConfigured: boolean;

  public isDialogVisible: boolean = false;
  public isServiceConnected: boolean = false;

  private destroyed$: Subject<void> = new Subject<void>();

  public created(): void {
    this.subscribeToServiceEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get isButtonVisible(): boolean {
    return this.isChatStoreConfigured && this.isServiceConnected;
  }

  public onEventChatButtonClick(): void {
    this.isDialogVisible = !this.isDialogVisible;
  }

  public onDialogClose(): void {
    this.isDialogVisible = false;
  }

  @Watch('isChatStoreConfigured', { immediate: true })
  private onIsChatStoreConfiguredChange(): void {
    if (!this.isChatStoreConfigured) {
      this.isDialogVisible = false;
    }
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (!this.isServiceConnected) {
      this.isDialogVisible = false;
    }
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

}
