


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import IconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';

@Component({
  name: 'event-chat-header',
  components: {
    IconCloseCircleOutline,
    IconArrowLeft,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
    }),
  }
})
export default class EventChatHeader extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly isContactsListVisible: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isCountContactsVisible: boolean;

  @Prop({ type: Error, default: null })
  public readonly contactsError: Error;

  @Prop({ type: Number, default: 0 })
  public readonly countContacts: number;

  public readonly event: TEvent;

  public get eventTitle(): string {
    return (this.event && this.event.title) || '';
  }

  public onCountContactsClick(): void {
    this.$emit('showContactsList');
  }

  public onButtonArrowLeftClick(): void {
    this.$emit('hideContactsList');
  }

  public onButtonCloseClick(): void {
    this.$emit('close');
  }

}
