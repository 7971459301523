

















































import {Component, Vue, Prop} from 'vue-property-decorator';

export enum IconContactLinksSpriteNames {
  NOTHING = 'nothing',
  FACEBOOK = 'facebook',
  LINKED_IN = 'linkedin',
  SKYPE = 'skype',
  TELEGRAM = 'telegram',
  TWITTER = 'twitter',
  VIBER = 'viber',
  WECHAT = 'wechat',
}
@Component
export default class IconContactLinksSprite extends Vue {

  @Prop({type: String, default: IconContactLinksSpriteNames.NOTHING })
  private spriteName: IconContactLinksSpriteNames;

  @Prop({type: Boolean, default: false})
  private isHovered: boolean;

  public spriteNames: typeof IconContactLinksSpriteNames = IconContactLinksSpriteNames;

}
