


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { NotificationType } from '@/_modules/promo/types/notification-type.enum';
import { mapGetters } from 'vuex';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import IconTwoCameras from '@/_modules/icons/components/icon-two-cameras.vue';
import { TDiscoveryWaitingMeetingNotificationResponse } from '@/_types/discovery/discovery-waiting-meeting-notification-response.type';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { TMeetingInvite } from '@/_types/meeting/meeting-invite.type';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';

const NOTIFICATION_SOUND = require('@/assets/sounds/intuition.ogg');

@Component({
  components: {
    IconTwoCameras,
  },
  computed: {
    ...mapGetters({
      promoPageByExternalId: 'promoStore/promoPageByExternalId'
    }),
  }
})
export default class PromoNotificationMeeting extends Vue {

  public readonly promoPageByExternalId: (externalId: string) => TPromoPage;
  public readonly NotificationType: typeof NotificationType = NotificationType;

  @Prop({ type: Object })
  public readonly notification: TDiscoveryWaitingMeetingNotificationResponse;

  @Prop({ type: Boolean, default: false })
  public readonly playSound: boolean;

  @Watch('notification', {immediate: true})
  public onNotificationChange(): void {
    if (this.playSound && this.notification) {
      const audio = new Audio(NOTIFICATION_SOUND);
      try { audio.play(); } catch { /* ignore */ }
    }
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get meetingInviteKey(): string {
    const meetingInvite: TMeetingInvite = {
      eventId: this.eventId,
      type: MeetingRoomType.MEETING,
      meetingId: this.notification.meetingId || undefined,
      meetingDate: this.notification.meetingDate,
      moderatorContactId: this.notification.moderatorContactId || undefined,
      externalId: this.notification.externalId || undefined,
    };

    return MeetingsHelper.generateMeetingInviteKey(meetingInvite);
  }

  public async onClick(): Promise<void> {
    await this.$router.push({
      name: 'meeting-invite',
      params: {
        inviteKey: this.meetingInviteKey,
        eventId: this.notification.eventId.toFixed(0),
      }
    }).catch(() => { /* ignore */ });

    this.hide();
  }

  public onButtonCloseClick(): void {
    this.hide();
  }

  public hide(): void {
    this.$store.dispatch('notificationsStore/setWaitingMeetingNotification', null);
  }

}
