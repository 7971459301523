


import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { TContactCard } from '@/_types/contact-card.type';
import { Action, Getter } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import ContactHelper from '@/_helpers/contact.helper';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import Tabs from '@/_ui/tabs/tabs.vue';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import ContactTabContentProfile
  from '@/_modules/contacts/components/contact-tab-content-profile/contact-tab-content-profile.vue';
import Messages from '@/_modules/promo/components/messages/messages.vue';
import { TMeeting } from '@/_types/meeting/meeting.type';
import { Moment } from 'moment';
import { TranslateResult } from 'vue-i18n';
import { TCancelMeetingParams, TConfirmMeetingParams } from '@/_api/meetings/meetings.api';
import IconTinyConfirm from '@/_modules/icons/components/icon-tiny-confirm.vue';
import IconTinyDecline from '@/_modules/icons/components/icon-tiny-decline.vue';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import ContactTabContentMeetings
  from '@/_modules/contacts/components/contact-tab-content-meetings/contact-tab-content-meetings.vue';
import {TEventSettings} from '@/_types/event-settings.type';

@Component({
  components: {
    Person,
    EwAvatar,
    Tabs,
    ContactTabContentProfile,
    ContactTabContentMeetings,
    Messages,
    IconTinyConfirm,
    IconTinyDecline,
  }
})
export default class OpenedContactCard extends Vue {

  @Ref('tabListWrapper') tabListWrapper: any; // Vue component or HTMLElement
  @Ref('contactCardRef') contactCardRef: any; // Vue component or HTMLElement
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;
  @Getter('contactsStore/openedContactCards') readonly openedContactCards: TContactCard[];
  @Getter('contactsStore/openedContactCardByContactId') readonly openedContactCardByContactId: (contactId: number) => TContactCard;
  @Getter('meetingsStore/getMeetingsByUserId') readonly meetingsByUserId: (userId: number) => TMeeting[];
  @Getter('promoPageStore/contact') readonly myself: TContact;
  @Getter('contactsStore/contactById') contactById: (contactId: number) => TContact;

  @Action('contactsStore/closeContactCard') closeContactCard: (params: { contactId: number }) => void;
  @Action('contactsStore/toggleContactCardIsMinimized') toggleContactCardIsMinimized: (params: { contactId: number }) => void;
  @Action('meetingsStore/confirmMeeting') confirmMeeting: (params: TConfirmMeetingParams) => void;
  @Action('meetingsStore/cancelMeeting') cancelMeeting: (params: TCancelMeetingParams) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public tabsSource: TTab[] = [
    {
      title: this.$t('title.profile'),
      isActive: true,
      rightTopBadge: null,
      index: 0,
      alias: 'profile',
    },
    {
      title: this.$t('organizerCabinet.pageMenu.meetings'),
      isActive: false,
      rightTopBadge: null,
      index: 1,
      alias: 'meetings',
    },
    {
      title: this.$t('title.messages'),
      isActive: false,
      rightTopBadge: null,
      index: 2,
      alias: 'messages',
    }
  ];

  public get eventSettingsLayout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get isDirectMessagingEnabled(): boolean {
    if (!this.isEventSettingsPolled) {
      return false;
    }

    if (!this.eventSettingsLayout) {
      return true;
    }

    return !this.eventSettingsLayout.is_direct_messaging_disabled === true;
  }

  public get tabList(): TTab[] {
    if (!this.isEventSettingsPolled || !this.isDirectMessagingEnabled) {
      return this.tabsSource.filter(tab => tab.alias !== 'messages');
    }

    return this.tabsSource;
  };

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isProfileTabActive(): boolean {
    return !!this.tabList.find(item => item.isActive && item.alias === 'profile');
  }

  public get isMeetingsTabActive(): boolean {
    return !!this.tabList.find(item => item.isActive && item.alias === 'meetings');
  }

  public get isMessagesTabActive(): boolean {
    return !!this.tabList.find(item => item.isActive && item.alias === 'messages');
  }

  @Prop({ type: Number })
  private readonly contactId: number;

  public get contactCard(): TContactCard {
    return this.openedContactCardByContactId(this.contactId);
  }

  public get contact(): TContact {
    if (this.contactCard && this.contactCard.contact) {
      return this.contactCard.contact;
    }
    return null;
  }

  public get contactFullName(): string | TranslateResult {
    if (!this.contact) {
      return '';
    }
    return this.contact.fullName || ContactHelper.getFullName(this.contact).trim() || this.$t('contacts.info.noname');
  }

  public get contactFirstName(): string | TranslateResult {
    if (!this.contact) {
      return '';
    }
    return this.contact.name || this.$t('contacts.info.noname');
  }

  public get isTinyMeetingRequestsBlockVisible(): boolean {
    const isViewingHimself: boolean = this.myself && this.myself.id === this.contactCard.contactId;
    return !isViewingHimself && this.preparedContactMeetingsToMe.length > 0;
  }

  public get preparedContactMeetingsToMe(): TMeeting[] {
    if (!this.contact || !this.myself) {
      return [];
    }
    return this.meetingsByUserId(this.myself.user_id).filter(meeting => {
      return meeting.creator_contact.id === this.contact.id
        && meeting.status === MeetingStatus.Unconfirmed;
    }).slice(0, 3);
  }

  public get contactUnreadMessages(): number {
    const contactId = (this.contactCard && this.contactCard.contact && this.contactCard.contact.id) || null;
    if (!contactId) {
      return 0;
    }
    const contactById: TContact = this.contactById(contactId);
    if (!contactById) {
      return 0;
    }
    return contactById.unread_messages;
  }

  public mounted(): void {
    this.activateStartupTab();
  }

  public activateStartupTab(): void {
    if (this.contactCard.startupTabName === 'messages') {
      this.tabsSource.forEach(tab => {
        tab.isActive = tab.alias === 'messages';
      });
      return;
    }
    if (this.contactCard.startupTabName === 'meetings') {
      this.tabsSource.forEach(tab => {
        tab.isActive = tab.alias === 'meetings';
      });
    }
  }

  @Watch('contactUnreadMessages', { immediate: true })
  public onUnreadMessagesChanged(newVal: number): void {
    const messagesTab: TTab = this.tabsSource.find(tab => tab.alias === 'messages');
    if (messagesTab) {
      messagesTab.rightTopBadge = newVal < 0 ? 0 : newVal > 99 ? 99 : newVal;
    }
  }

  public toggleMinimized(): void {
    if (!this.contactCard) {
      return;
    }
    this.closeSharer();
    this.toggleContactCardIsMinimized({ contactId: this.contactId });
  }

  private close(): void {
    this.closeSharer();
    this.closeContactCard({ contactId: this.contactId });
    if (this.isUrlManagementNeeded()) {
      this.manageContactUrl();
    }
  }

  public isUrlManagementNeeded(): boolean {
    return this.$route.meta && this.$route.meta.isContactUrlManagementNeeded === true;
  }

  private async manageContactUrl(): Promise<void> {
    const savedScrollY: number = window.scrollY;
    const savedScrollX: number = window.scrollX;

    if (this.openedContactCards.length) {
      await this.changeUrlToLastOpenContact();
      window.scrollTo(savedScrollX, savedScrollY);
      return;
    }
    await this.changeUrlToParentView();
    window.scrollTo(savedScrollX, savedScrollY);
  }

  private async changeUrlToLastOpenContact(): Promise<void> {
    await this.$router.push({
      name: 'promo-page-contacts-contact',
      params: { contact_id: this.openedContactCards[this.openedContactCards.length - 1].contactId.toFixed(0) },
      query: this.$route.query,
    }).catch(e => e);
  }

  private async changeUrlToParentView(): Promise<void> {
    const routeName: string = this.$route.name;

    switch (routeName) {
      case 'promo-contacts':
        return await this.changeUrlToContactList();
      case 'promo-page-calendar':
        return await this.changeUrlToMeetings();
    }

  }

  private async changeUrlToContactList(): Promise<void> {
    await this.$router.push({
      name: 'promo-contacts',
      query: this.$route.query,
    }).catch(e => e);
  }

  private async changeUrlToMeetings(): Promise<void> {
    await this.$router.push({
      name: 'promo-page-calendar',
      params: {}
    }).catch(e => e);
  }

  private onContactCardHeaderClick(): void {
    if (this.contactCard.isMinimized) {
      this.toggleMinimized();
    }
  }

  public onActiveTabUpdate(tab: TTab): void {
    this.tabsSource.forEach(sourceTab => {
      sourceTab.isActive = sourceTab.alias === tab.alias;
    });
  }

  public monthNames: string[] = [
    this.$t('datepicker.monthNamesFull.january') as string,
    this.$t('datepicker.monthNamesFull.february') as string,
    this.$t('datepicker.monthNamesFull.march') as string,
    this.$t('datepicker.monthNamesFull.april') as string,
    this.$t('datepicker.monthNamesFull.may') as string,
    this.$t('datepicker.monthNamesFull.june') as string,
    this.$t('datepicker.monthNamesFull.july') as string,
    this.$t('datepicker.monthNamesFull.august') as string,
    this.$t('datepicker.monthNamesFull.september') as string,
    this.$t('datepicker.monthNamesFull.october') as string,
    this.$t('datepicker.monthNamesFull.november') as string,
    this.$t('datepicker.monthNamesFull.december') as string,
  ];

  public dayNames: string[] = [
    this.$t('standaloneCompany.dayNames.sunday') as string,
    this.$t('standaloneCompany.dayNames.monday') as string,
    this.$t('standaloneCompany.dayNames.tuesday') as string,
    this.$t('standaloneCompany.dayNames.wednesday') as string,
    this.$t('standaloneCompany.dayNames.thursday') as string,
    this.$t('standaloneCompany.dayNames.friday') as string,
    this.$t('standaloneCompany.dayNames.saturday') as string,
  ];

  public getTinyMeetingTitle(meeting: TMeeting): string | TranslateResult {
    const startDateMoment: Moment = this.$moment(meeting.date_start);
    const replaces: { [key: string]: string } = {
      START_TIME: startDateMoment.format('HH:mm'),
      MONTH: this.monthNames[meeting.date_start.getMonth()],
      DAY_NUMBER: startDateMoment.format('D'),
      DAY_NAME: this.dayNames[meeting.date_start.getDay()],
      YEAR_NUMBER: (new Date()).getFullYear() !== meeting.date_start.getFullYear() ? ' ' + startDateMoment.format('Y') : '',
    };
    return this.$t('tinyMeetingRequests.itemTitleTemplate', replaces);
  }

  public isMeetingOnline(meeting: TMeeting): boolean {
    return !!meeting
      && !this.isMeetingExpired(meeting)
      && !!meeting.communication_type
      && meeting.communication_type !== 'offline';
  }

  public isMeetingOffline(meeting: TMeeting): boolean {
    return !!meeting
      && !this.isMeetingExpired(meeting)
      && !!meeting.communication_type
      && meeting.communication_type === 'offline';
  }

  public isMeetingExpired(meeting: TMeeting): boolean {
    return !!meeting
      && !!meeting.date_start
      && (meeting.date_start.getTime() < (new Date()).getTime());
  }

  public isConfirmMeetingActionAvailable(meeting: TMeeting): boolean {
    return !!meeting
      && !!meeting.status
      && (meeting.status === MeetingStatus.Unconfirmed)
      && !this.isMeetingExpired(meeting);
  }

  public get contactCardClassNames(): { [key: string]: boolean } {
    return {
      minimized: this.contactCard.isMinimized,
      'active-tab-info': this.isProfileTabActive,
      'active-tab-messages': this.isMessagesTabActive,
      'active-tab-meetings': this.isMeetingsTabActive,
    };
  }
}
