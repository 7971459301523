


import {Vue, Component, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import CookiesHelper from '@/_helpers/cookies-helper';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {TContact} from '@/_types/contact.type';
import {TActivateTicketCodeParams} from '@/_modules/events/api/event/event.api';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';

const PARAM_NAME = 'promopage_code';
const COOKIE_NAME = 'ewCompanyOwnerToken';
const COOKIE_VALUE_SEPARATOR = '|||';

@Component({
  components: {
    SimplePopup,
  }
})
export default class PromopageCodeAcceptor extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isLoading') isAuthLoading: boolean;
  @Getter('promoPageStore/contact') myself: TContact;

  @Action('_eventStore/activateTicketCode') activateTicketCode: (params: TActivateTicketCodeParams) => Promise<boolean>;
  @Action('promoPageStore/getContactPromoPage') getCompanyOfCurrentContact: (eventId: number) => Promise<TPromoPage>;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get code(): string {
    // Route.query.code can be an array. We will only accept it if it is a string.
    if (this.eventId && this.$route.query && this.$route.query[PARAM_NAME] && !Array.isArray(this.$route.query[PARAM_NAME])) {
      return (this.$route.query[PARAM_NAME] as string).trim();
    }
    return '';
  }

  public get savedEventAndCodePair(): { eventId: number; code: string } {
    const cookieVal: string = CookiesHelper.readCookie(COOKIE_NAME) || '';
    const [ eventId, promopageCode ] = cookieVal.split(COOKIE_VALUE_SEPARATOR);
    if (!cookieVal || !eventId || !promopageCode ) {
      return { eventId: null, code: null };
    }
    return {
      eventId: parseInt(eventId, 10) || null,
      code: promopageCode.trim(),
    };
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  @Watch('code', {immediate: true})
  private onCodeChange(): void {
    if (!this.code) {
      return;
    }
    this.saveCodeToCookie();
    this.clearParamFromUrl();
    this.attachCompanyOwner();
  }

  public saveCodeToCookie(): void {
    const hours = 24;
    const days = 1;
    const CODE_COOKIE_MAX_AGE = 60 * 60 * hours * days;
    CookiesHelper.createCookie({name: COOKIE_NAME, value: this.getCookieValue(), maxAge: CODE_COOKIE_MAX_AGE});
  }

  public getCookieValue(): string {
    return this.eventId.toFixed(0) + COOKIE_VALUE_SEPARATOR + this.code;
  }

  public clearParamFromUrl(): void {
    this.$router.push({
      name: 'event-info',
      params: {
        ...this.$route.params,
        [PARAM_NAME]: null,
      }
    });
  }

  public async attachCompanyOwner(): Promise<void> {
    const { eventId, code } = this.savedEventAndCodePair;
    if (!eventId || !code || !this.myself || this.myself.event_id !== eventId ) {
      window.setTimeout(this.attachCompanyOwner, 3000);
      return;
    }

    if (this.hasRoleInAnotherCompany()) {
      return;
    }

    const isRequestSuccessful: boolean = await this.activateTicketCode({
      eventId,
      code,
    });

    if (isRequestSuccessful) {
      this.clearCookie();
      await this.getCompanyOfCurrentContact(this.eventId);
      return;
    }

    window.setTimeout(this.attachCompanyOwner, 1500);
  }

  public hasRoleInAnotherCompany(): boolean {
    const { eventId } = this.savedEventAndCodePair;
    return !!(this.myself && this.myself.promopage_external_id && this.eventId === eventId);
  }

  public clearCookie(): void {
    CookiesHelper.deleteCookie(COOKIE_NAME);
  }

}
