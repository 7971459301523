<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4444 17.4444H6.55556V6.55556H11V5H6.55556C5.69222 5 5 5.7 5 6.55556V17.4444C5 18.3 5.69222 19 6.55556 19H17.4444C18.3 19 19 18.3 19 17.4444V13H17.4444V17.4444ZM13.5556 5V6.55556H16.3478L8.70222 14.2011L9.79889 15.2978L17.4444 7.65222V10.4444H19V5H13.5556Z"
      fill="white"
      fill-opacity="0.95"
    />
  </svg>
</template>
<script type="ts">
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class IconPinBack extends Vue {

}
</script>
