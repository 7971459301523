import { render, staticRenderFns } from "./remove-coworker-confirmation.html?vue&type=template&id=bcc91d7a&scoped=true&"
import script from "./remove-coworker-confirmation.vue?vue&type=script&lang=ts&"
export * from "./remove-coworker-confirmation.vue?vue&type=script&lang=ts&"
import style0 from "./remove-coworker-confirmation.scss?vue&type=style&index=0&id=bcc91d7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc91d7a",
  null
  
)

export default component.exports