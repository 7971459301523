











import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-download',
})
export default class IconDownload extends Vue {

}
