import { render, staticRenderFns } from "./presence-confirmation.html?vue&type=template&id=4a6050d6&scoped=true&"
import script from "./presence-confirmation.vue?vue&type=script&lang=ts&"
export * from "./presence-confirmation.vue?vue&type=script&lang=ts&"
import style0 from "./presence-confirmation.scss?vue&type=style&index=0&id=4a6050d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a6050d6",
  null
  
)

export default component.exports