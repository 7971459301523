


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'remove-coworker-confirmation',

})
export default class RemoveCoworkerConfirmation extends Vue {

  public accept(): void {
    this.$store.dispatch('promoPageStore/setRemoveCoworkerConfirmation', true);
  }

  public cancel(): void {
    this.$store.dispatch('promoPageStore/setRemoveCoworkerPopupVisible', false);
  }

}
