

import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import * as TRTC from 'trtc-js-sdk';
import { Client, LocalStream, PlayOptions, RemoteStream } from 'trtc-js-sdk';
import { TranslateResult } from 'vue-i18n';
import { Moment } from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import TRTCHelper from '@/_modules/meeting-rooms/helpers/trtc.helper';
import { TRTCMode } from '@/_modules/meeting-rooms/types/trtc-mode.enum';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TContact } from '@/_types/contact.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import IconFullScreen from '@/_modules/icons/components/icon-full-screen.vue';
import IconMicrophone from '@/_modules/icons/components/icon-microphone.vue';
import IconCamera from '@/_modules/icons/components/icon-camera.vue';
import IconShareScreen from '@/_modules/icons/components/icon-share-screen.vue';
import IconMeetingInvite from '@/_modules/icons/components/icon-meeting-invite.vue';
import IconArrowDown from '@/_modules/icons/components/icon-arrow-down.vue';
import IconCrossedOut from '@/_modules/icons/components/icon-crossed-out.vue';
import ItemsMenu, { TItemsMenuItem } from '@/_modules/controls/components/items-menu/items-menu.vue';
import IconClock from '@/_modules/icons/components/icon-clock.vue';
import IconSmileSad from '@/_modules/icons/components/icon-smile-sad.vue';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {Action, Getter} from 'vuex-class';

TRTC.Logger.setLogLevel(Number(process.env.VUE_APP_TENCENT_MEETING_APP_LOG_LEVEL) as 0 | 1 | 2 | 3 | 4 | 5);

const SPEAKING_CHECK_INTERVAL = 1000;
const SPEAKING_AUDIO_LEVEL_THRESHOLD = 0.01;
const DEFAULT_PLAYER_CONFIG_LOCAL: PlayOptions = { objectFit: 'cover', muted: true };
const DEFAULT_PLAYER_CONFIG_REMOTE: PlayOptions = { objectFit: 'cover', muted: false };
const DEFAULT_PLAYER_CONFIG_REMOTE_SS: PlayOptions = { objectFit: 'contain', muted: false };

type TParticipant = {
  trtcUserId: string;
  eventId: number;
  contactId: number;
  contact: TContact;
  isSpeaking: boolean;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  stream: LocalStream | RemoteStream;
  isLocal: boolean;
  isScreenSharing: boolean;
  audioLevel: number;
};

type TParticipantsArrangement = {
  rows: number;
  cols: number;
  cellWidth?: number;
  cellHeight?: number;
  area?: number;
};

@Component({
  props: {
    meetingRoomId: Number,
  },
  components: {
    IconFullScreen,
    ItemsMenu,
    IconMicrophone,
    IconCamera,
    IconShareScreen,
    IconMeetingInvite,
    IconArrowDown,
    IconCrossedOut,
    IconClock,
    IconSmileSad,
  },
  computed: {
    ...mapGetters({
      getMeetingRoomById: 'meetingRoomsStore/getMeetingRoomById',
      contactById: 'contactsStore/contactById',
    }),
  },
})
export default class MeetingRoom extends Vue implements IFixedDraggableChild {

  @Action('ewSharerStore/closeSharer') closeSharer: () => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Getter('ewSharerStore/isSharerVisible') isSharerVisible: boolean;

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();

  public readonly getMeetingRoomById: (meetingRoomId: string) => TMeetingRoomState;
  public readonly contactById: (contactId: number) => TContact;

  public meetingRoomId: string;
  public trtcClient: Client = null;
  public isConnecting: boolean = true;
  public isSystemRequirementsOK: boolean = true;
  public isSystemCheckErrorMessageVisible: boolean = true;

  public accessTime: string = '00:00:00';
  public accessTimeIntervalId: number = null;
  public accessTimeStart: Moment = null;

  public speakingCheckInterval: number = null;

  public cameras: MediaDeviceInfo[] = [];
  public microphones: MediaDeviceInfo[] = [];
  public isCameraAccessDenied: boolean = false;
  public isMicrophoneAccessDenied: boolean = false;
  public isChooseCameraMenuVisible: boolean = false;
  public isChooseMicrophoneMenuVisible: boolean = false;
  public cameraId: string = null;
  public microphoneId: string = null;

  public screenShareClient: Client = null;
  public screenShareStream: LocalStream = null;
  public isScreenSharingSupported: boolean = false;
  public isScreenSharingActive: boolean = false;
  public isScreenSharingStatusChanging: boolean = false;

  // TODO: minimized participants list
  public participants: TParticipant[] = [];

  public localStream: LocalStream = null;
  public isLocalStreamPublished: boolean = false;

  public timeRangeErrorType: 'future' | 'past';
  public isTimeRangeOK: boolean = null;

  // public isInviteInformationPopupVisible: boolean = false;

  private privateIsLocalAudioMuted: boolean = false;
  private privateIsLocalVideoMuted: boolean = false;
  private privateIsDestroyed: boolean = false;
  private destroyed$: Subject<void> = new Subject<void>();

  public get meetingRoom(): TMeetingRoomState {
    return this.getMeetingRoomById(this.meetingRoomId);
  }

  public get id(): number {
    return this.meetingRoom && this.meetingRoom.id;
  }

  public get isMinimized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMinimized;
  }

  public get isMaximized(): boolean {
    return this.meetingRoom && this.meetingRoom.isMaximized;
  }

  public get config(): TMeetingRoomConfig {
    return this.meetingRoom && this.meetingRoom.config;
  }

  public get eventId(): number {
    return this.config && this.config.eventId;
  }

  public get meetingId(): number {
    return this.config && this.config.meetingId;
  }

  public get meetingDate(): number {
    return this.config && this.config.meetingDate;
  }

  public get localContactId(): number {
    return this.config && this.config.contactId;
  }

  private get localTrtcUserId(): string {
    return this.config && MeetingRoomsHelper.getLocalTrtcUserId(this.config);
  }

  public get isLocalAudioMuted(): boolean {
    return !this.microphoneId || this.privateIsLocalAudioMuted || this.isMicrophoneAccessDenied;
  }

  public get isLocalVideoMuted(): boolean {
    return !this.cameraId || this.privateIsLocalVideoMuted || this.isCameraAccessDenied;
  }

  public get toggleMuteLabel(): TranslateResult {
    return this.isLocalAudioMuted ? this.$t('meetingRooms.unmute') : this.$t('meetingRooms.mute');
  }

  public get toggleVideoLabel(): TranslateResult {
    return this.isLocalVideoMuted ? this.$t('meetingRooms["resume video"]') : this.$t('meetingRooms["stop video"]');
  }

  public get toggleMuteTitle(): TranslateResult {
    return (!this.microphoneId || this.isMicrophoneAccessDenied) ? this.$t('meetingRooms.noAvailableMicrophones') : this.toggleMuteLabel;
  }

  public get toggleVideoTitle(): TranslateResult {
    return (!this.cameraId || this.isCameraAccessDenied) ? this.$t('meetingRooms.noAvailableCameras') : this.toggleVideoLabel;
  }

  public get isCameraAndMicrophoneAccessDenied(): boolean {
    return this.isCameraAccessDenied && this.isMicrophoneAccessDenied;
  }

  public get toggleScreenShareTitle(): TranslateResult {
    if (!this.isScreenSharingSupported) {
      return this.$t('meetingRooms.screenShareNotSupported');
    }
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get toggleScreenShareLabel(): TranslateResult {
    return this.isScreenSharingActive ? this.$t('meetingRooms.stopScreenShare') : this.$t('meetingRooms.startScreenShare');
  }

  public get meetingShareUrl(): string {
    return MeetingsHelper.getMeetingInviteUrl({
      type: MeetingRoomType.MEETING,
      eventId: this.eventId,
      meetingId: this.meetingId,
      meetingDate: this.meetingDate,
    });
  }

  public get timeRangeErrorMessageSpecific(): TranslateResult {
    switch (this.timeRangeErrorType) {
      case 'future':
        return this.$t('meetingRooms.timeRangeErrorMessageFuture')
          + ' '
          + this._displayDate(this.meetingDate);

      case 'past':
        return this.$t('meetingRooms.timeRangeErrorMessagePast');

      default:
        return '';
    }
  }

  public get microphonesMenuItems(): TItemsMenuItem[] {
    if (!this.microphones) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.microphones.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public get camerasMenuItems(): TItemsMenuItem[] {
    if (!this.cameras) {
      return [];
    }
    const items: TItemsMenuItem[] = [];
    this.cameras.forEach((device: any): void => {
      items.push({
        value: device.deviceId,
        label: device.label,
      });
    });
    return items;
  }

  public created(): void {
    this.cameraId = localStorage.getItem('cameraId') || null;
    this.microphoneId = localStorage.getItem('microphoneId') || null;
    this.isScreenSharingSupported = TRTC.isScreenShareSupported() || false;
  }

  public async mounted(): Promise<void> {

    this._createLocalParticipant();

    this.isSystemRequirementsOK = (await TRTC.checkSystemRequirements()).result || false;
    if (!this.isSystemRequirementsOK) {
      return;
    }

    this.isTimeRangeOK = this._checkTimeRange();
    if (!this.isTimeRangeOK) {
      return;
    }

    this._subscribeToGlobalEvents();

    await this._checkForVideoAudioAccess();
    if (this.isCameraAndMicrophoneAccessDenied) {
      return;
    }

    this.trtcClient = TRTC.createClient(TRTCHelper.createClientConfig({
      type: MeetingRoomType.MEETING,
      userId: this.localTrtcUserId,
      mode: TRTCMode.RTC,
    }));
    this._subscribeTrtcClientEvents();

    if (this.isScreenSharingSupported) {
      this.screenShareClient = TRTC.createClient(TRTCHelper.createClientConfig({
        type: MeetingRoomType.MEETING,
        userId: this.localTrtcUserId + '_SS',
        mode: TRTCMode.RTC,
        autoSubscribe: false, // N.B.: this flag replaced Client.setDefaultMuteRemoteStreams() in the TRTC JS SDK
      }));
    }

    this._join();
    this._startSpeakingCheck();
  }

  public beforeDestroy(): void {
    this.privateIsDestroyed = true;
    this._stopScreenSharing();
    if (this.screenShareClient) {
      this.screenShareClient.leave();
    }
    this._stopSpeakingCheck();
    this._stopCountingAccessTime();
    this._disconnectFromTRTC();
    this.dragZoneMouseDown$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onTopCenterMouseDown(event: MouseEvent): void {
    if (!this.isMinimized) {
      return;
    }
    this.dragZoneMouseDown$.next(event);
  }

  public toggleMinimize(): void {
    if (this.isMinimized) {
      this.$store.dispatch('meetingRoomsStore/unMinimize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/minimize', this.id);
    }
  }

  public toggleMaximize(): void {
    if (this.isMaximized) {
      this.$store.dispatch('meetingRoomsStore/unMaximize', this.id);
    } else {
      this.$store.dispatch('meetingRoomsStore/maximize', this.id);
    }
  }

  public leave(): void {
    this.$store.dispatch('meetingRoomsStore/leave', this.id);
  }

  public toggleScreenShare(): void {
    if (!this.isScreenSharingSupported || this.isScreenSharingStatusChanging) {
      return;
    }
    if (!this.isScreenSharingActive) {
      this._startScreenSharing();
    } else {
      this._stopScreenSharing();
    }
  }

  public toggleMute(): void {
    if (!this.localStream || !this.microphoneId) {
      return;
    }
    if (this.privateIsLocalAudioMuted) {
      this._unmuteLocalAudio();
    } else {
      this._muteLocalAudio();
    }
  }

  public toggleVideo(): void {
    if (!this.localStream || !this.cameraId) {
      return;
    }
    if (this.privateIsLocalVideoMuted) {
      this._unmuteLocalVideo();
    } else {
      this._muteLocalVideo();
    }
  }

  public onSystemCheckErrorOkClick(): void {
    this.isSystemCheckErrorMessageVisible = false;
  }

  public onChooseCameraClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseCameraMenuVisible = !this.isChooseCameraMenuVisible;
    this.isChooseMicrophoneMenuVisible = false;
  }

  public onChooseMicrophoneClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isChooseMicrophoneMenuVisible = !this.isChooseMicrophoneMenuVisible;
    this.isChooseCameraMenuVisible = false;
  }

  public async chooseCamera(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('video', deviceId);
    this.cameraId = deviceId;
    if (this.privateIsLocalVideoMuted) {
      this._muteLocalVideo();
    } else {
      this._unmuteLocalVideo();
    }
  }

  public async chooseMicrophone(deviceId: string): Promise<void> {
    if (!this.localStream) {
      return;
    }
    await this.localStream.switchDevice('audio', deviceId);
    this.microphoneId = deviceId;
    if (this.privateIsLocalAudioMuted) {
      this._muteLocalAudio();
    } else {
      this._unmuteLocalAudio();
    }
  }

  public async toggleFullScreen(participant: TParticipant): Promise<void> {
    if (
      !this.$refs['player-' + participant.trtcUserId]
      || !(this.$refs['player-' + participant.trtcUserId] as HTMLDivElement[])[0]
    ) {
      return;
    }
    const playerElementId = 'player-' + participant.trtcUserId;
    const playerElement = (this.$refs[playerElementId] as HTMLDivElement[])[0];

    const fullscreenElement =
      document.fullscreenElement
      // @ts-ignore
      || document.webkitFullscreenElement
      // @ts-ignore
      || document.mozFullScreenElement
      // @ts-ignore
      || document.msFullscreenElement
      // @ts-ignore
      || document.webkitCurrentFullScreenElement;

    if (fullscreenElement) {
      const exitFullScreen =
        document.exitFullscreen
        // @ts-ignore
        || document.webkitExitFullscreen
        // @ts-ignore
        || document.mozCancelFullScreen
        // @ts-ignore
        || document.msExitFullscreen;
      if (exitFullScreen) {
        await exitFullScreen.call(document);
      }
    } else {
      const requestFullScreen =
        playerElement.requestFullscreen
        // @ts-ignore
        || playerElement.webkitRequestFullscreen
        // @ts-ignore
        || playerElement.mozRequestFullScreen
        // @ts-ignore
        || playerElement.msRequestFullscreen;
      if (requestFullScreen) {
        await requestFullScreen.call(playerElement);
      }
    }
  }

  public async exitFullScreen(participant: TParticipant): Promise<void> {
    if (
      !this.$refs['player-' + participant.trtcUserId]
      || !(this.$refs['player-' + participant.trtcUserId] as HTMLDivElement[])[0]
    ) {
      return;
    }
    const playerElementId = 'player-' + participant.trtcUserId;
    const fullscreenElement =
      document.fullscreenElement
      // @ts-ignore
      || document.webkitFullscreenElement
      // @ts-ignore
      || document.mozFullScreenElement
      // @ts-ignore
      || document.msFullscreenElement
      // @ts-ignore
      || document.webkitCurrentFullScreenElement;

    if (fullscreenElement && fullscreenElement.getAttribute('id') === playerElementId) {
      const exitFullScreen =
        document.exitFullscreen
        // @ts-ignore
        || document.webkitExitFullscreen
        // @ts-ignore
        || document.mozCancelFullScreen
        // @ts-ignore
        || document.msExitFullscreen;
      if (exitFullScreen) {
        await exitFullScreen.call(document);
      }
    }
  }

  public onMeetingShareClick(event: PointerEvent): void {
    if (this.isSharerVisible) {
      this.closeSharer();
      return;
    }
    this.openSharer({
      eventTarget: event.target as Element,
      url: this.meetingShareUrl
    });
  }

  // public onMeetingShareCancelClick(): void {
  //   this.isInviteInformationPopupVisible = false;
  // }

  // public onMeetingShareDialogClick(event: MouseEvent): void {
  //   event.stopPropagation();
  // }

  // public onMeetingShareConfirmClick(): void {
  //   this.isInviteInformationPopupVisible = false;
  //   if (!this.$refs.meetingSharer) {
  //     return;
  //   }
  //   (this.$refs.meetingSharer as any).showSharer();
  // }

  private _createLocalParticipant(): void {
    const localParticipant: TParticipant = {
      trtcUserId: this.localTrtcUserId,
      eventId: this.eventId,
      contactId: this.localContactId,
      contact: null,
      isSpeaking: false,
      isAudioMuted: false,
      isVideoMuted: false,
      stream: null,
      isLocal: true,
      isScreenSharing: false,
      audioLevel: 0.0,
    };
    this.participants.push(localParticipant);
    this.participants = [ ...this.participants ];
    this.loadContact(localParticipant);

    // // TODO: used for testing
    // let nextIndex = 1;
    // setInterval(() => {
    //
    //   const newParticipant: TParticipant = {
    //     trtcUserId: this.localTrtcUserId + '_' + nextIndex++,
    //     eventId: this.eventId,
    //     contactId: this.localContactId,
    //     contact: null,
    //     isSpeaking: false,
    //     isAudioMuted: false,
    //     isVideoMuted: false,
    //     stream: null,
    //     isLocal: false,
    //     isScreenSharing: false,
    //     audioLevel: 0.0,
    //   };
    //   this.participants.push(newParticipant);
    //   this.loadContact(newParticipant);
    //
    //   // if (nextIndex > 15) {
    //   //   this.currentParticipantsPage = 2;
    //   // }
    // }, 2000);
  }

  private async loadContact(participant: TParticipant): Promise<void> {
    if (participant.contact) {
      return;
    }
    participant.contact = this.contactById(participant.contactId);
    if (!participant.contact) {
      await this.$store.dispatch('contactsStore/requestContact', {
        contactId: participant.contactId
      });
      participant.contact = this.contactById(participant.contactId);
    }
  }

  private _subscribeToGlobalEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);

    fromEvent<MouseEvent>(window, 'beforeunload')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onWindowBeforeUnload);

    fromEvent<MouseEvent>(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onWindowResize);
  }

  private onWindowResize(): void {
    this.recalculateAspectRatio();
  }

  private recalculateAspectRatio(): void {
    this.$nextTick(() => {
      const container = this.$refs.participants as HTMLDivElement;
      if (!container) {
        return;
      }
      const participantsNodes = container.querySelectorAll('.participant');
      const participantsCount = participantsNodes.length;
      if (participantsCount < 1) {
        return;
      }

      const possibleArrangements: TParticipantsArrangement[] = [];
      for (let rows = 1; rows <= participantsCount; rows++) {
        for (let cols = 1; cols <= participantsCount; cols++) {
          if (
            rows * cols < participantsCount
            || (rows - 1) * cols >= participantsCount
            || rows * (cols - 1) >= participantsCount
          ) {
            continue;
          }
          possibleArrangements.push({ rows, cols });
        }
      }

      if (!possibleArrangements.length) {
        return;
      }

      const containerWidth = container.offsetWidth - 2;
      const containerHeight = container.offsetHeight - 2;
      const ratio = 16.0 / 9.0;
      let optimalArrangement: TParticipantsArrangement = null;

      possibleArrangements.forEach(arrangement => {
        const { rows, cols } = arrangement;
        arrangement.cellWidth = Math.floor(containerWidth / cols);
        arrangement.cellHeight = Math.floor(arrangement.cellWidth * (ratio > 1 ? ( 1 / ratio) : ratio));
        if (arrangement.cellHeight * rows > containerHeight) {
          arrangement.cellHeight = Math.floor(containerHeight / rows);
          arrangement.cellWidth = Math.floor(arrangement.cellHeight * (ratio > 1 ? ratio : ( 1 / ratio)));
        }
        arrangement.area = arrangement.cellWidth * cols + arrangement.cellHeight * rows;
        if (!optimalArrangement || optimalArrangement.area < arrangement.area) {
          optimalArrangement = arrangement;
        }
      });

      if (!optimalArrangement) {
        return;
      }

      participantsNodes.forEach(node => {
        (node as HTMLDivElement).style.width = `${optimalArrangement.cellWidth}px`;
        (node as HTMLDivElement).style.height = `${optimalArrangement.cellHeight}px`;
      });
    });
  }

  private onDocumentClick(): void {
    this.isChooseCameraMenuVisible = false;
    this.isChooseMicrophoneMenuVisible = false;
    // this.isInviteInformationPopupVisible = false;
  }

  private onWindowBeforeUnload(event: Event): string {
    if (this.isCameraAndMicrophoneAccessDenied) {
      return undefined;
    }
    event.returnValue = false; // 'Sure?';
    return 'Sure?';
  }

  private _stopSpeakingCheck(): void {
    if (this.speakingCheckInterval) {
      clearInterval(this.speakingCheckInterval);
      this.speakingCheckInterval = null;
    }
  }

  private _stopCountingAccessTime(): void {
    if (!this.accessTimeIntervalId) {
      return;
    }
    clearInterval(this.accessTimeIntervalId);
    this.accessTimeIntervalId = null;
  }

  private async _disconnectFromTRTC(): Promise<void> {
    await this._trtcClientDisconnect();
    this._stopLocalStream();
    this._stopRemoteStreams();
  }

  private async _trtcClientDisconnect(): Promise<void> {
    if (!this.trtcClient) {
      return;
    }
    if (this.localStream) {
      try {
        await this.trtcClient.unpublish(this.localStream);
      } catch (error) { /* do nothing */
      }
    }
    try {
      await this.trtcClient.leave();
    } catch (error) { /* do nothing */ }
    this.trtcClient.off('*');
  }

  private _stopLocalStream(): void {
    if (!this.localStream) {
      return;
    }
    try {
      this.localStream.stop();
      this.localStream.close();
    } catch {
      /* ignore */
    }
    this.localStream = null;
    this.participants[0].stream = null;
  }

  private _stopRemoteStreams(): void {
    this.participants.forEach((participant: TParticipant) => {
      if (participant.isLocal || !participant.stream) {
        return;
      }
      participant.stream.stop();
      participant.stream = null;
    });
  }

  private _muteLocalAudio(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.muteAudio();
    this.privateIsLocalAudioMuted = true;
    this.participants[0].isAudioMuted = true;
  }

  private _unmuteLocalAudio(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.unmuteAudio();
    this.privateIsLocalAudioMuted = false;
    this.participants[0].isAudioMuted = false;
  }

  private _muteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.muteVideo();
    this.privateIsLocalVideoMuted = true;
    this.participants[0].isVideoMuted = true;
  }

  private _unmuteLocalVideo(): void {
    if (!this.localStream) {
      return;
    }
    this.localStream.unmuteVideo();
    this.privateIsLocalVideoMuted = false;
    this.participants[0].isVideoMuted = false;
  }

  private async _checkForVideoAudioAccess(): Promise<void> {
    try {
      const cameraResult = await navigator.permissions.query({ name: 'camera' });
      if (cameraResult.state === 'denied') {
        this.isCameraAccessDenied = true;
      }
      const microphoneResult = await navigator.permissions.query({ name: 'microphone' });
      if (microphoneResult.state === 'denied') {
        this.isMicrophoneAccessDenied = true;
      }
    } catch (error) {
      /* do nothing */
    }
  }

  private _subscribeTrtcClientEvents(): void {

    this.trtcClient.on('stream-added', evt => {
      const remoteStream: RemoteStream = evt.stream;
      const trtcUserId = remoteStream.getUserId();
      const matches = (/^(\d+)_(\d+)_(\d+)(_SS)?$/gi).exec(trtcUserId);
      if (!matches) {
        return;
      }
      const eventId = parseInt(matches[1], 10);
      const meetingId = parseInt(matches[2], 10);
      const contactId = parseInt(matches[3], 10);
      const isScreenSharing = !!matches[4];
      if (eventId !== this.eventId || meetingId !== this.meetingId) {
        return;
      }
      const existingParticipant = this.participants.find((participant: TParticipant) => {
        return participant.trtcUserId === trtcUserId;
      });
      if (existingParticipant) {
        if (existingParticipant.stream) {
          existingParticipant.stream.stop();
        }
        existingParticipant.stream = remoteStream;
      } else {
        const newParticipant: TParticipant = {
          trtcUserId: trtcUserId,
          eventId: eventId,
          contactId: contactId,
          contact: null,
          isSpeaking: false,
          isAudioMuted: false,
          isVideoMuted: false,
          stream: remoteStream,
          isLocal: false,
          isScreenSharing,
          audioLevel: 0.0,
        };
        this.participants.push(newParticipant);
        this.participants = [ ...this.participants ];
        this.loadContact(newParticipant);
      }
      Vue.nextTick(() => {
        if (isScreenSharing) {
          remoteStream.play('player-' + trtcUserId, DEFAULT_PLAYER_CONFIG_REMOTE_SS);
        } else {
          remoteStream.play('player-' + trtcUserId, DEFAULT_PLAYER_CONFIG_REMOTE);
        }
      });
    });

    this.trtcClient.on('stream-removed', evt => {
      const remoteStream: RemoteStream = evt.stream;
      const trtcUserId = remoteStream.getUserId();
      let existingParticipantIndex = -1;
      const existingParticipant = this.participants.find((participant: TParticipant, index: number) => {
        existingParticipantIndex = index;
        return participant.trtcUserId === trtcUserId;
      });
      if (!existingParticipant) {
        return;
      }
      if (existingParticipant.stream) {
        existingParticipant.stream.stop();
        existingParticipant.stream = null;
      }
      this.participants.splice(existingParticipantIndex, 1);
      this.participants = [ ...this.participants ];
    });

    this.trtcClient.on('mute-audio', evt => {
      const trtcUserId = evt.userId;
      const existingParticipant = this.participants.find((participant: TParticipant) => {
        return participant.trtcUserId === trtcUserId;
      });
      if (!existingParticipant) {
        return;
      }
      existingParticipant.isAudioMuted = true;
    });

    this.trtcClient.on('unmute-audio', evt => {
      const trtcUserId = evt.userId;
      const existingParticipant = this.participants.find((participant: TParticipant) => {
        return participant.trtcUserId === trtcUserId;
      });
      if (!existingParticipant) {
        return;
      }
      existingParticipant.isAudioMuted = false;
    });

    this.trtcClient.on('mute-video', evt => {
      const trtcUserId = evt.userId;
      const existingParticipant = this.participants.find((participant: TParticipant) => {
        return participant.trtcUserId === trtcUserId;
      });
      if (!existingParticipant) {
        return;
      }
      existingParticipant.isVideoMuted = true;
      this.exitFullScreen(existingParticipant);
    });

    this.trtcClient.on('unmute-video', evt => {
      const trtcUserId = evt.userId;
      const existingParticipant = this.participants.find((participant: TParticipant) => {
        return participant.trtcUserId === trtcUserId;
      });
      if (!existingParticipant) {
        return;
      }
      existingParticipant.isVideoMuted = false;
    });
  }

  private _startSpeakingCheck(): void {
    this._stopSpeakingCheck();
    this.speakingCheckInterval = window.setInterval(() => {
      if (this.privateIsDestroyed) {
        this._stopSpeakingCheck();
        return;
      }
      this.participants.forEach((participant: TParticipant) => {
        if (!participant.stream) {
          participant.isSpeaking = false;
          return;
        }
        const audioLevel = participant.stream.getAudioLevel();
        participant.isSpeaking = audioLevel > SPEAKING_AUDIO_LEVEL_THRESHOLD;
        participant.audioLevel = audioLevel;
      });

      /* Not used - sort everyone except current user by loudness */
      // const first = this.participants.splice(0, 1);
      // this.participants.sort((a: TParticipant, b: TParticipant) => {
      //   return a.audioLevel > b.audioLevel ? -1 : 1;
      // });
      // this.participants.unshift(first[0]);

    }, SPEAKING_CHECK_INTERVAL);
  }

  private async _join(): Promise<void> {
    const localTrtcUserId = this.localTrtcUserId;
    let cameras: any[] = [];
    let microphones: any[] = [];
    try {
      cameras = await TRTC.getCameras();
    } catch {
      /* do nothing */
    }
    try {
      microphones = await TRTC.getMicrophones();
    } catch {
      /* do nothing */
    }

    await this.trtcClient.join({ roomId: this.id });

    let cameraId = this.cameraId;
    let microphoneId = this.microphoneId;
    this.cameras = cameras || [];
    this.microphones = microphones || [];

    if (this.cameras.length) {
      let selectedCamera;
      if (cameraId !== null) {
        selectedCamera = this.cameras.find(camera => /* !!camera.deviceId && */ camera.deviceId === cameraId);
      }
      if (!selectedCamera) {
        selectedCamera = cameras[0];
      }
      cameraId = selectedCamera ? selectedCamera.deviceId : null;
    } else {
      cameraId = null;
    }
    if (cameraId === null) {
      this.cameras = [];
    }

    if (this.microphones.length) {
      let selectedMicrophone;
      if (microphoneId !== null) {
        selectedMicrophone = microphones.find(microphone => /* !!microphone.deviceId && */ microphone.deviceId === microphoneId);
      }
      if (!selectedMicrophone) {
        selectedMicrophone = microphones[0];
      }
      microphoneId = selectedMicrophone ? selectedMicrophone.deviceId : null;
    } else {
      microphoneId = null;
    }
    if (microphoneId === null) {
      this.microphones = [];
    }

    this.localStream = null;
    let localStream: LocalStream;

    try {
      localStream = TRTC.createStream({
        userId: localTrtcUserId,
        audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
        video: this.cameras.length > 0 && !this.isCameraAccessDenied,
        cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
        microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        mirror: true,
      });
      // localStream.setVideoProfile('720p');
      // localStream.setAudioProfile('standard');
      await localStream.initialize();
    } catch {
      /* mac os says permissions are 'granted' but then denies the permission */
      /* will try to init stream without camera and then without microphone */
      try {
        localStream = TRTC.createStream({
          userId: localTrtcUserId,
          audio: this.microphones.length > 0 && !this.isMicrophoneAccessDenied,
          video: false,
          microphoneId: this.isMicrophoneAccessDenied ? undefined : (microphoneId || undefined),
        });
        // localStream.setAudioProfile('standard');
        await localStream.initialize();
        this.isCameraAccessDenied = true;
      } catch {
        this.isMicrophoneAccessDenied = true;
        try {
          localStream = TRTC.createStream({
            userId: localTrtcUserId,
            audio: false,
            video: this.cameras.length > 0 && !this.isCameraAccessDenied,
            cameraId: this.isCameraAccessDenied ? undefined : (cameraId || undefined),
            mirror: true,
          });
          // localStream.setVideoProfile('720p');
          await localStream.initialize();
        } catch {
          this.isCameraAccessDenied = true;
        }
      }
    }

    this.localStream = localStream || null;
    this.participants[0].stream = this.localStream;
    if (!this.localStream) {
      this.isCameraAccessDenied = true;
      this.isMicrophoneAccessDenied = true;
      return;
    }

    if (!cameraId || !microphoneId) {
      cameras = [];
      microphones = [];
      try {
        cameras = await TRTC.getCameras();
      } catch {
        /* do nothing */
      }
      try {
        microphones = await TRTC.getMicrophones();
      } catch {
        /* do nothing */
      }

      const videoTrack = this.localStream.getVideoTrack();
      const audioTrack = this.localStream.getAudioTrack();
      if (videoTrack) {
        const videoTrackSettings = videoTrack.getSettings();
        cameraId = videoTrackSettings.deviceId;
      }
      if (audioTrack) {
        const audioTrackSettings = audioTrack.getSettings();
        microphoneId = audioTrackSettings.deviceId;
      }

      this.cameras = cameras;
      this.microphones = microphones;
    }

    this.cameraId = cameraId;
    this.microphoneId = microphoneId;

    this._setIsConnecting(false);

    Vue.nextTick(() => {
      this.localStream.play('player-' + this.localTrtcUserId, DEFAULT_PLAYER_CONFIG_LOCAL);
    });

    await this._publishLocalStream();

    await this.screenShareClient.join({
      roomId: this.id
    });
  }

  private _setIsConnecting(isConnecting: boolean): void {
    this.isConnecting = isConnecting;
    if (!this.isConnecting) {
      this._startCountingAccessTime();
    }
  }

  private _startCountingAccessTime(): void {
    if (this.accessTimeIntervalId) {
      clearInterval(this.accessTimeIntervalId);
    }
    this.accessTime = '00:00:00';
    this.accessTimeStart = this.$moment();
    this.accessTimeIntervalId = window.setInterval(() => {
      const currentTime = this.$moment();
      const diff = currentTime.diff(this.accessTimeStart);
      this.accessTime = this.$moment(diff).utc(false).format('HH:mm:ss');
    }, 1000);
  }

  private async _publishLocalStream(): Promise<void> {
    if (this.isLocalStreamPublished) {
      return;
    }
    await this.trtcClient.publish(this.localStream);
    this.isLocalStreamPublished = true;
    this._startCountingAccessTime();
  }

  @Watch('cameraId')
  private _onCameraIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('cameraId', newValue);
    } else {
      localStorage.removeItem('cameraId');
    }
  }

  @Watch('microphoneId')
  private _onMicrophoneIdChange(newValue: string): void {
    if (newValue) {
      localStorage.setItem('microphoneId', newValue);
    } else {
      localStorage.removeItem('microphoneId');
    }
  }

  @Watch('isMinimized')
  private onIsMinimizedChange(): void {
    this.recalculateAspectRatio();
  }

  @Watch('isMaximized')
  private onIsMaximizedChange(): void {
    this.recalculateAspectRatio();
  }

  @Watch('participants', { immediate: true })
  private onParticipantsChange(): void {
    this.recalculateAspectRatio();
  }

  private _startScreenSharing(): void {
    if (!this.screenShareClient || this.isScreenSharingActive || this.isScreenSharingStatusChanging) {
      return;
    }

    this.isScreenSharingActive = true;
    this.isScreenSharingStatusChanging = true;

    try {
      this.screenShareStream = TRTC.createStream({
        userId: this.localTrtcUserId,
        audio: false,
        video: false,
        screen: true,
      });
      this.screenShareStream.initialize().then(() => {
        this.screenShareStream.on('screen-sharing-stopped', (): void => {
          this._stopScreenSharing();
        });
        this.screenShareClient.publish(this.screenShareStream);
      }).catch(() => {
        this._stopScreenSharing();
      });
    } catch (error) {
      this._stopScreenSharing();
    } finally {
      this.isScreenSharingStatusChanging = false;
    }
  }

  private async _stopScreenSharing(): Promise<void> {
    if (!this.screenShareClient || !this.isScreenSharingActive) {
      return;
    }
    this.isScreenSharingActive = false;
    this.isScreenSharingStatusChanging = true;

    if (this.screenShareStream) {
      try {
        await this.screenShareClient.unpublish(this.screenShareStream);
      } catch { /* ignore */ }
    }

    if (this.screenShareStream) {
      try {
        this.screenShareStream.close();
        this.screenShareStream = null;
      } catch { /* ignore */ }
    }

    this.isScreenSharingStatusChanging = false;
  }

  private _checkTimeRange(): boolean {
    const meetingDate = this.$moment(this.meetingDate * 1000);
    const now = this.$moment();
    this.timeRangeErrorType = null;
    if (now.isBefore(meetingDate, 'day')) {
      this.timeRangeErrorType = 'future';
      return false;
    } else if (now.isAfter(meetingDate, 'day')) {
      this.timeRangeErrorType = 'past';
      return false;
    }

    return true;
  }

  private _displayDate(timestamp: number): string {
    if (!timestamp) {
      return '';
    }
    return this.$moment(timestamp * 1000).format(DateTimeFormat.ONLY_DATE);
  }
}
