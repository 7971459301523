


import {Vue, Component, Prop} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import statisticsApi from '@/_modules/statistics/api/statistics.api';
import {TContact} from '@/_types/contact.type';
import {TDiscoverySessionOnlineCheck} from '@/_types/discovery/discovery-session-online-check.type';

const SECONDS_BEFORE_SELF_CLOSE = 30;

@Component
export default class PresenceConfirmation extends Vue {

  @Getter('promoProgramStore/getPresenceQuestionData') getPresenceQuestionData: TDiscoverySessionOnlineCheck;
  @Getter('promoPageStore/contact') contact: TContact;
  @Action('promoProgramStore/hideFloatingProgramCardPresenceCheck') hideFloatingProgramCardPresenceCheck: (programId: number) => void;
  @Action('promoProgramStore/setPresenceQuestionData') setPresenceQuestionData: (params: TDiscoverySessionOnlineCheck) => void;

  public selfClosingIntervalId: number = 0;
  public secondsElapsed: number = 0;
  public secondsBeforeSelfClose: number = SECONDS_BEFORE_SELF_CLOSE;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Prop(Number)
  public readonly programId: number;

  public get programSessionId(): number {
    return this.programId || this.getPresenceQuestionData.programSessionId;
  }

  public get secondsRemaining(): number {
    return Math.max(0, this.secondsBeforeSelfClose - this.secondsElapsed);
  }

  public mounted(): void {
    this.selfClosingIntervalId = window.setInterval(this.dispatchSelfClose, 1000);
  }

  public beforeDestroy(): void {
    window.clearInterval(this.selfClosingIntervalId);
  }

  public dispatchSelfClose(): void {
    if (this.secondsRemaining > 0) {
      this.secondsElapsed++;
      return;
    }
    window.clearInterval(this.selfClosingIntervalId);
    this.setPresenceQuestionData(null);
    this.hideFloatingProgramCardPresenceCheck(this.programSessionId);
  }

  public async sendStat(): Promise<void> {
    const eventId = this.eventId;
    const actionName = this.getPresenceQuestionData.type;
    const data = {
      contactId: this.contact.id,
      contact: this.contact,
      programSessionId: this.programSessionId,
      type: 'WEB'
    };

    await statisticsApi.putStat({ eventId, actionName, data });
    await this.$store.dispatch('promoProgramStore/setPresenceQuestionData', null);
    await this.hideFloatingProgramCardPresenceCheck(this.programSessionId);
  }
}
