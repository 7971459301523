


import { Vue, Component } from 'vue-property-decorator';
import IconEventChat from '@/_modules/icons/components/icon-event-chat.vue';

@Component({
  components: {
    IconEventChat,
  },
})
export default class EventChatButton extends Vue {

  public onClick(event: MouseEvent): void {
    this.$emit('click', event);
  }

}
