


import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import { TMeeting } from '@/_types/meeting/meeting.type';
import TimePicker, {
  MeetingTimeSlotPickerMode
} from '@/_modules/standalone-company/components/time-picker/time-picker.vue';
import EwCalendar from '@/_modules/ew-calendar/ew-calendar.vue';
import { TScheduleDay } from '@/_modules/ew-calendar/types/ew-calendar.type';
import { TSimpleTimeSlot } from '@/_modules/standalone-company/types/standalone-company-store-state.type';
import IconEditMeetingRequest from '@/_modules/icons/components/icon-edit-meeting-request.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import { MeetingCommunicationTypes, TCancelMeetingParams, TRequestMeetingParams } from '@/_api/meetings/meetings.api';
import { AxiosResponse } from 'axios';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';

@Component({
  components: {
    TimePicker,
    EwCalendar,
    IconEditMeetingRequest,
    EwButton,
  }
})
export default class ContactTabContentMeetings extends Vue {

  @Getter('_eventStore/event') public readonly event: TEvent;
  @Getter('promoPageStore/contact') public readonly myself: TContact;
  @Getter('meetingsStore/getMeetingsByUserId') readonly meetingsByUserId: (userId: number) => TMeeting[];
  @Action('meetingsStore/requestMeeting') requestAMeeting: (params: TRequestMeetingParams) => Promise<AxiosResponse>;
  @Action('meetingsStore/cancelMeeting') cancelAMeeting: (params: TCancelMeetingParams) => Promise<AxiosResponse>;

  public selectedDayValue: Date = null;
  public MeetingTimeSlotPickerModes: typeof MeetingTimeSlotPickerMode = MeetingTimeSlotPickerMode;
  public isRequestedMeetingOffline: boolean = false;
  public timeSlotBeingRequested: TSimpleTimeSlot = null;
  public meetingBeingCancelled: TMeeting = null;
  public isNewMeetingDetailsScreenVisible: boolean = false;
  public isCancellationConfirmationScreenVisible: boolean = false;
  public monthNames: string[] = [
    this.$t('datepicker.monthNamesFull.january') as string,
    this.$t('datepicker.monthNamesFull.february') as string,
    this.$t('datepicker.monthNamesFull.march') as string,
    this.$t('datepicker.monthNamesFull.april') as string,
    this.$t('datepicker.monthNamesFull.may') as string,
    this.$t('datepicker.monthNamesFull.june') as string,
    this.$t('datepicker.monthNamesFull.july') as string,
    this.$t('datepicker.monthNamesFull.august') as string,
    this.$t('datepicker.monthNamesFull.september') as string,
    this.$t('datepicker.monthNamesFull.october') as string,
    this.$t('datepicker.monthNamesFull.november') as string,
    this.$t('datepicker.monthNamesFull.december') as string,
  ];
  public dayNames: string[] = [
    this.$t('standaloneCompany.dayNames.sunday') as string,
    this.$t('standaloneCompany.dayNames.monday') as string,
    this.$t('standaloneCompany.dayNames.tuesday') as string,
    this.$t('standaloneCompany.dayNames.wednesday') as string,
    this.$t('standaloneCompany.dayNames.thursday') as string,
    this.$t('standaloneCompany.dayNames.friday') as string,
    this.$t('standaloneCompany.dayNames.saturday') as string,
  ];
  public meetingRequestOptionalMessage: string = '';
  public isNewMeetingSavingInProgress: boolean = false;

  @Prop({ type: Object, default: null })
  private readonly contact: TContact;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get selectedDay(): Date {
    return this.selectedDayValue;
  }

  public set selectedDay(value: Date) {
    this.selectedDayValue = value;
  }

  public onCalendarDayChange(day: TScheduleDay): void {
    this.selectedDayValue = day.fullDate;
  }

  public onNewMeetingRequest(timeSlot: TSimpleTimeSlot): void {
    this.showNewMeetingDetailsScreen(timeSlot);
  }

  public showNewMeetingDetailsScreen(timeSlot: TSimpleTimeSlot): void {
    this.timeSlotBeingRequested = timeSlot;
    this.setRequestedMeetingTypeOnline();
    this.isNewMeetingDetailsScreenVisible = true;
  }

  public hideNewMeetingDetailsScreen(): void {
    this.isNewMeetingDetailsScreenVisible = false;
  }

  public onCancelMeeting(payload: { meeting: TMeeting }): void {
    const { meeting } = payload;
    if (!meeting
      || !meeting.id
      || meeting.status === MeetingStatus.Canceled
      || meeting.creator_contact.id !== this.myself.id) {
      return;
    }

    this.meetingBeingCancelled = meeting;
    this.isCancellationConfirmationScreenVisible = true;

  }

  public async proceedWithMeetingCancellation(): Promise<void> {
    // TODO: progress indicator
    await this.cancelAMeeting({
      event_id: this.eventId,
      meeting_id: this.meetingBeingCancelled.id,
    });

    this.meetingBeingCancelled = null;
    this.isCancellationConfirmationScreenVisible = false;
  }

  public abortMeetingCancellation(): void {
    this.meetingBeingCancelled = null;
    this.isCancellationConfirmationScreenVisible = false;
  }

  // TODO: move into a helper
  private getFormattedMeetingInfo(dateStart: Date, dateEnd: Date): string {
    const diffMinutes: number = Math.floor((dateEnd.getTime() - dateStart.getTime()) / 1000 / 60);
    const year: number = dateStart.getFullYear();
    const monthName: string = this.monthNames[dateStart.getMonth()];
    const dayName: string = this.dayNames[dateStart.getDay()];
    const dayNumber: number = dateStart.getDate();
    const hhStart: number = dateStart.getHours();
    const mmStart: number = dateStart.getMinutes();
    const hhEnd: number = dateEnd.getHours();
    const mmEnd: number = dateEnd.getMinutes();

    return this.$t('standaloneCompany.meetingSuccessTemplate', {
      diffMinutes,
      year,
      monthName,
      dayNumber,
      dayName,
      hhmmStart: hhStart.toFixed(0).padStart(2, '0') + ':' + mmStart.toFixed(0).padStart(2, '0'),
      hhmmEnd: hhEnd.toFixed(0).padStart(2, '0') + ':' + mmEnd.toFixed(0).padStart(2, '0'),
    }) as string;
  }

  public setRequestedMeetingTypeOffline(): void {
    this.isRequestedMeetingOffline = true;
  }

  public setRequestedMeetingTypeOnline(): void {
    this.isRequestedMeetingOffline = false;
  }

  public get isSubmitNewRequestButtonDisabled(): boolean {
    return this.isNewMeetingSavingInProgress;
  }

  public async onSubmitMeetingRequest(): Promise<void> {

    if (this.isSubmitNewRequestButtonDisabled) {
      return;
    }

    this.isNewMeetingSavingInProgress = true;
    const requestMeetingParams = this.prepareRequestMeetingParams();

    const response: AxiosResponse<TMeeting> = await this.requestAMeeting(requestMeetingParams);

    if (requestMeetingParams.title) {
      await this.$store.dispatch('messagesStore/sendMessage', {
        eventId: this.eventId,
        userId: requestMeetingParams.user_id,
        text: requestMeetingParams.title
      });
    }

    if (response && response.data && (response.data as TMeeting).id) {
      this.hideNewMeetingDetailsScreen();
    } else if (((response as unknown) as any).error) {
      // TODO: display error
    }

    this.isNewMeetingSavingInProgress = false;
  }

  public prepareRequestMeetingParams(): TRequestMeetingParams {
    const params: TRequestMeetingParams = {
      date_end: DateTimeHelper.dateToApiDate(this.timeSlotBeingRequested.dateEnd),
      date_start: DateTimeHelper.dateToApiDate(this.timeSlotBeingRequested.dateStart),
      event_id: this.eventId,
      user_id: this.contact.user_id
    };

    if (this.isRequestedMeetingOffline) {
      params.communication_type = MeetingCommunicationTypes.OFFLINE;
    }

    const paramsTitle: string = this.meetingRequestOptionalMessage.trim();
    if (paramsTitle) {
      params.title = paramsTitle;
    }

    return params;
  }
}
