


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';
import { mapGetters } from 'vuex';
import ValidationHelper from '@/_helpers/validation.helper';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import IconLiveUnpin from '@/_modules/icons/components/icon-live-unpin.vue';
import IconZoomIn from '@/_modules/icons/components/icon-zoom-in.vue';
import IconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';

@Component({
  name: 'live-media-block',
  components: {
    IconLiveUnpin,
    IconZoomIn,
    IconZoomOut
  },
  computed: {
    ...mapGetters({
      isIntroMediaUnpinned: 'cabinetLobbyStore/isIntroMediaUnpinned',
      isAgendaMediaUnpinned: 'cabinetLobbyStore/isAgendaMediaUnpinned',
    }),
  }
})
export default class LiveMediaBlock extends Vue implements IFixedDraggableChild {

  // PROPS

  @Prop({ type: Object })
  public readonly livePageData: TLivePage;

  @Prop({ type: String, default: 'intro' })
  public readonly blockName: string;

  @Prop({ type: Boolean, default: false })
  public readonly isUnpinned: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isFullscreen: boolean;

  // COMPUTED

  public get videoPreviewIntroPosterClicked(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get video_player_frame(): string {
    let result: string;
    switch(this.blockName) {
      case 'intro':
        result = this.livePageData.video_player_frame;
        break;
      case 'agenda':
        result = this.livePageData.agenda_video_player_frame;
        break;
      default:
        result = this.livePageData.video_player_frame;
    }
    return result;
  }

  public get video_file_url(): string {
    let result: string;
    switch(this.blockName) {
      case 'intro':
        result = this.livePageData.video_file_url;
        break;
      case 'agenda':
        result = this.livePageData.agenda_video_file_url;
        break;
      default:
        result = this.livePageData.video_file_url;
    }
    return result;
  }

  // WATCH

  // @Watch('unpinnedMediaItems', { immediate: false })
  // private onUnpinnedMediaItemsChange(): void {
  //   this.onCarouselItemChange(this.activeSlideIndex);
  // }

  // DATA

  public dragZoneMouseDown$: Subject<MouseEvent> = new Subject<MouseEvent>();
  public videoPreviewPosterClicked: boolean = false;

  // HOOKS

  public beforeDestroy(): void {
    this.dragZoneMouseDown$.complete();
  }

  // METHODS

  public onTopCenterMouseDown(event: MouseEvent): void {
    this.dragZoneMouseDown$.next(event);
  }

  public fullScreen(blockName: string): void {
    this.$emit('fullscreenRequested', blockName);
  }

  public toggleUnpinned(blockName: string): void {
    this.$store.dispatch('cabinetLobbyStore/toggleUnpinned', blockName);
  }

  public unpin(blockName: string): void {
    this.$store.dispatch('cabinetLobbyStore/unpin', blockName);
  }

  public closeUnpinned(blockName: string): void {
    this.$store.dispatch('cabinetLobbyStore/closeUnpinned', blockName);
  }

  /* Validate frame code before output
   */
  private isPlayerCodeValid(htmlString: string): boolean {
    return (htmlString !== '') && ValidationHelper.isValidVideoStreamEmbed(htmlString);
  }

  /* Naive: is it an image?
   */
  private isImageExtension(url: string): boolean {
    if (!url) return false;
    const whiteList = {
      jpg: true,
      png: true,
      gif: true,
      jpe: true,
      jpeg: true,
      svg: true
    };

    const urlParts: string[] = url.split('.');
    const ext = urlParts.pop().toLowerCase();
    return Object.prototype.hasOwnProperty.call(whiteList, ext);

  }

  /* Video poster click handler
   * Poster is simply one more <video> element.
   * Click hides it and shows the main <video> starting from 00:00
   */
  private setVideoPosterClicked(): void {
    this.videoPreviewPosterClicked = true;
    this.$nextTick(() => {
      (this.$refs.videoPreview as HTMLVideoElement).currentTime = 0;
      (this.$refs.videoPreview as HTMLVideoElement).play();
    });
  }

  /* For video previews, try seek to around PERCENTAGE% of video duration
   * in hopes that the frame at PERCENTAGE% is not solid color
   * Solid color previews look like there is nothing.
   * First frame of a video is often solid black or other color.
   */
  private seekTo(targetRefName: string): void {
    const PERCENTAGE = 0.025;

    if (this.$refs[targetRefName] && PERCENTAGE) {
      try {
        const vid = (this.$refs[targetRefName] as HTMLVideoElement);
        vid.currentTime = vid.duration * PERCENTAGE;
      } catch (e) {
      }
    }

  }

}
