


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import {ConferenceProgramChatType} from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import {TChatGroupState} from '@/_modules/chat/types/chat-group-state.type';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import ChatMessageComposer from '@/_modules/chat/components/chat-message-composer/chat-message-composer.vue';
import ChatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import eventDiscoveryService, {TEventDiscoveryServiceConfig} from '@/_services/event-discovery.service';

@Component({
  components: {
    ChatMessageComposer,
    ChatMessages,
  }
})
export default class ProgramChatWrapper extends Vue {

  @Getter('chatStore/getChatGroupStateByGroupId') getChatGroupStateByGroupId: (groupId: string) => TChatGroupState;

  @Prop(Object)
  public program: TConferenceProgram;

  @Prop({type: String, default: (): ConferenceProgramChatType => { return null; }})
  public selectedChatType: ConferenceProgramChatType;

  public readonly ConferenceProgramChatType: typeof ConferenceProgramChatType = ConferenceProgramChatType;
  private destroyed$: Subject<void> = new Subject<void>();

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  public get liveChatGroupId(): string {
    return this.program.show_live_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.LIVE) : null;
  }

  public get liveChatGroupState(): TChatGroupState {
    const liveChatGroupId = this.liveChatGroupId;
    return (liveChatGroupId && this.getChatGroupStateByGroupId(liveChatGroupId)) || null;
  }

  public get speakerChatGroupId(): string {
    return this.program.show_speaker_chat ? ChatHelper.getProgramChatGroupId(this.program, ConferenceProgramChatType.SPEAKER) : null;
  }

  public get speakerChatGroupState(): TChatGroupState {
    const speakerChatGroupId = this.speakerChatGroupId;
    return (speakerChatGroupId && this.getChatGroupStateByGroupId(speakerChatGroupId)) || null;
  }

  public get currentChatGroupId(): string {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      return this.liveChatGroupId;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      return this.speakerChatGroupId;
    }
    return null;
  }

  public get currentChatGroupState(): TChatGroupState {
    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      return this.liveChatGroupState;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      return this.speakerChatGroupState;
    }
    return null;
  }

  public get enterError(): Error {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.enterError) || null;
  }

  public get isEntered(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isEntered) || false;
  }

  public get isEmptyCurrentChat(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return !currentChatGroupState || !currentChatGroupState.messages.length;
  }

  public get isChatMessageComposerDisabled(): boolean {
    return !this.isEntered || !!this.enterError;
  }

  public get messagesError(): Error {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.messagesError) || null;
  }

  public get isMessagesLoading(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isMessagesLoading) || false;
  }

  public get isAllMessagesLoaded(): boolean {
    const currentChatGroupState = this.currentChatGroupState;
    return (currentChatGroupState && currentChatGroupState.isAllMessagesLoaded) || false;
  }

  @Watch('program', { immediate: true })
  private onProgramChange(): void {
    this.subscribeToServiceEvents();
  }

  @Watch('selectedChatType', {immediate: false})
  public onSelectedChatTypeChange(): void {
    this.enterChatsAutomatically();
  }

  public onMessagesScrollTopReached(): void {
    const isMessagesLoading = this.isMessagesLoading;
    const isAllMessagesLoaded = this.isAllMessagesLoaded;
    const currentChatGroupId = this.currentChatGroupId;
    if (!currentChatGroupId || isMessagesLoading || isAllMessagesLoaded) {
      return;
    }
    this.$store.dispatch('chatStore/requestChatGroupMessagesPage', currentChatGroupId);
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    if (!config) {
      return;
    }
    this.enterChatsAutomatically();
  }

  private enterChatsAutomatically(): void {
    if (!this.selectedChatType) {
      return;
    }
    this.enterChat();
  }

  public enterChat(): void {

    let chatGroupId: string;
    let chatGroupState: TChatGroupState;

    if (this.selectedChatType === ConferenceProgramChatType.LIVE) {
      chatGroupId = this.liveChatGroupId;
      chatGroupState = this.liveChatGroupState;
    } else if (this.selectedChatType === ConferenceProgramChatType.SPEAKER) {
      chatGroupId = this.speakerChatGroupId;
      chatGroupState = this.speakerChatGroupState;
    }

    if (chatGroupId && !chatGroupState) {
      this.$store.dispatch('chatStore/enterChatGroup', chatGroupId);
      this.$store.dispatch('chatStore/requestChatGroupMessagesPage', chatGroupId);
      // this.$store.dispatch('chatStore/requestChatGroupContacts', liveChatGroupId);
    }
  }
}
