


import {Vue, Component, Watch} from 'vue-property-decorator';
import { TContactCard } from '@/_types/contact-card.type';
import { Getter, Action } from 'vuex-class';
import OpenedContactCard from '@/_modules/contacts/components/opened-contact-card/opened-contact-card.vue';

@Component({
  components: {
    OpenedContactCard,
  }
})
export default class OpenedContactsViewer extends Vue {

  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('contactsStore/openedContactCards') readonly openedContactCards: TContactCard[];
  @Getter('contactsStore/openedContactCardByContactId') readonly openedContactCardByContactId: (contactId: number) => TContactCard;
  @Getter('sideBarRightStore/isOpen') readonly isSideBarRightOpen: boolean;
  @Getter('sideBarRightStore/isSideBarRightComponentPresent') readonly isSideBarRightPresent: boolean;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;
  @Action('contactsStore/requestContact') requestContact: (params: { contactId: number; force?: boolean }) => void;
  @Action('contactsStore/clearOpenedContactCards') clearOpenedContactCards: () => void;

  @Watch('isAuthenticated')
  private onIsAuthenticatedChange(newVal: boolean): void {
    if (!newVal) {
      this.clearOpenedContactCards();
    }
  }

  public get isShiftedByRightMenu(): boolean {
    return this.isSideBarRightOpen && this.isSideBarRightPresent;
  }

  public get isOpenedContactsViewerVisible(): boolean {
    return !!(this.openedContactCards && this.openedContactCards.length);
  }

  public isUrlManagementNeeded(): boolean {
    return this.$route.meta && this.$route.meta.isContactUrlManagementNeeded === true;
  }

  public get contactId(): number {
    return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id, 10)) || null;
  }

  public mounted(): void {
    this.openContactCardFromUrl();
  }

  public async openContactCardFromUrl(): Promise<void> {
    if (this.isUrlManagementNeeded() && this.contactId && !this.openedContactCardByContactId(this.contactId)) {
      await this.requestContact({contactId: this.contactId, force: true});
      this.openContactCard({contactId: this.contactId});
    }
  }
}
