<template>
  <svg class="main-svg" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
      stroke-width="1.5" stroke="black"/>
    <rect x="8.5" y="8.5" width="13" height="13" rx="1.5" stroke="black"/>
    <rect x="13" y="13" width="7" height="7" rx="1" fill="black"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconLiveUnpin = Vue.extend({
  name: 'icon-live-unpin',
});
export default IconLiveUnpin;
</script>

<style>
.main-svg {
  width: 30px;
  height: 30px;
}
</style>
