


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import FormInputText from '@/_modules/controls/components/form-input-text/form-input-text.vue';
import ValidationHelper from '@/_helpers/validation.helper';
import IconDownload from '@/_modules/icons/components/icon-download.vue';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import promoProgramApi, { TPatchConferenceProgramParams } from '@/_modules/promo-program/api/promo-program.api';

type TFormData = {
  embedCode: string;
}

@Component({
  name: 'embed-code-form',
  components: {
    FormInputText,
    IconDownload,
  },
  computed: {
    ...mapGetters({
      promoPage: 'promoPageStore/promoPage',
      isPromoPageLoading: 'promoPageStore/isPromoPageLoading',
      getProgramById: 'promoProgramStore/getProgramById',
    }),
  },
  validations: {
    formData: {
      embedCode: {
        isValidVideoStreamEmbed: ValidationHelper.isValidVideoStreamEmbed,
      }
    }
  }
})
export default class EmbedCodeForm extends Vue {

  @Prop({ type: Object })
  public readonly config: TMeetingRoomConfig;

  public readonly promoPage: TPromoPage;
  public readonly isPromoPageLoading: boolean;
  public readonly event: TEvent;
  public readonly contact: TContact;
  public readonly getProgramById: (programId: number) => TConferenceProgram;

  public get broadcastType(): BroadcastType {
    return (this.config && this.config.broadcastType) || null;
  }

  public get isEmptyConferenceProgram(): boolean {
    return this.config && this.config.broadcastType === BroadcastType.PROGRAM_SPEAKER && !this.config.programId;
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null;
  }

  public get programId(): number {
    return (this.config && this.config.broadcastType === BroadcastType.PROGRAM_SPEAKER && this.config.programId) || null;
  }

  public get currentProgramEditLanguage(): string {
    return (this.config && this.config.lang) || 'en';
  }

  public get program(): TConferenceProgram {
    if (!this.programId) {
      return null;
    }
    return this.getProgramById(this.programId);
  }

  public get isLoading(): boolean {
    const broadcastType = this.broadcastType;
    return !broadcastType
      || (broadcastType === BroadcastType.PROMO_CONTACT && this.isPromoPageLoading);
  }

  public get externalId(): string {
    return this.promoPage ? this.promoPage.external_id : null;
  }

  public get exampleURL(): string {
    return 'https://support.google.com/youtube/answer/171780?hl=' + this.$i18n.locale;
  }

  public formData: TFormData = {
    embedCode: '',
  };
  public isSaving: boolean = false;

  public async onSubmitClick(): Promise<void> {
    if (this.isLoading || this.isSaving) {
      return;
    }
    this.$v.formData.$touch();
    if (this.$v.formData.$pending || this.$v.formData.$invalid) {
      /* vuelidation error(s) */
      return;
    }

    this.isSaving = true;
    const broadcastType = this.broadcastType;
    const eventId = this.eventId;

    if (broadcastType === BroadcastType.PROMO_CONTACT) {
      const externalId = this.externalId;
      await this.$store.dispatch('promoStore/updatePromoPage', {
        event_id: eventId,
        external_id: externalId,
        video_stream_embed: this.formData.embedCode,
      });
      await this.$store.dispatch('promoStore/promoPageVideoStreamEmbedUpdated', {
        event_id: eventId,
        external_id: externalId,
        video_stream_embed: this.formData.embedCode,
      });
    } else if (broadcastType === BroadcastType.PROGRAM_SPEAKER) {
      const program = this.program;
      if (program) {
        const patchConferenceProgramParams: TPatchConferenceProgramParams = {
          id: program.id,
          event_id: eventId,
          conference_id: program.conference_id,
          video_stream_embed: this.formData.embedCode,
          lang: this.currentProgramEditLanguage
        };
        try {
          await promoProgramApi.patchConferenceProgram(patchConferenceProgramParams);
          await this.$store.dispatch('promoProgramStore/videoStreamEmbedUpdated', {
            programId: program.id,
            video_stream_embed: this.formData.embedCode,
          });
        } catch (error) {
          // TODO:
          this.isSaving = false;
        }
      }
    }

    this.isSaving = false;
    this.$emit('saved');
  }

  @Watch('promoPage', { immediate: true })
  private onPromoPageChange(): void {
    this.setFormData();
  }

  @Watch('program', { immediate: true })
  private onProgramChange(): void {
    this.setFormData();
  }

  private setFormData(): void {
    const broadcastType = this.broadcastType;
    if (broadcastType === BroadcastType.PROMO_CONTACT) {
      if (!this.promoPage && this.formData.embedCode) {
        return;
      }
      this.formData.embedCode = this.promoPage.video_stream_embed || '';
    } else if (broadcastType === BroadcastType.PROGRAM_SPEAKER) {
      const program = this.program;
      if (!program) {
        return;
      }
      this.formData.embedCode = program.video_stream_embed || '';
    }
  }

  private manualEmbedBroadcastYoutubeURL(): string {
    const lang: string = this.$route.params.lang;

    switch (lang) {
      case 'en':
        return '/documents/Instructions for setting up a Youtube video broadcast_EN.pdf';
      case 'ru':
        return '/documents/Instructions for setting up a Youtube video broadcast_RU.pdf';
      case 'uk':
        return '/documents/Instructions for setting up a Youtube video broadcast_UKR.pdf';
      case 'lt':
        return '/documents/Instructions for setting up a Youtube video broadcast_EN.pdf';
      case 'zh':
        return '/documents/Instructions for setting up a Youtube video broadcast_EN.pdf';
      default:
        return '/documents/Instructions for setting up a Youtube video broadcast_EN.pdf';
    }
  }

  private manualEmbedStreamingYoutubeURL(): string {
    const lang: string = this.$route.params.lang;
    switch (lang) {
      case 'en':
        return '/documents/Instructions for setting up live streaming from Youtube_EN.pdf';
      case 'ru':
        return '/documents/Instructions for setting up live streaming from Youtube_RU.pdf';
      case 'uk':
        return '/documents/Instructions for setting up live streaming from Youtube_UKR.pdf';
      case 'lt':
        return '/documents/Instructions for setting up live streaming from Youtube_EN.pdf';
      case 'zh':
        return '/documents/Instructions for setting up live streaming from Youtube_EN.pdf';
      default:
        return '/documents/Instructions for setting up live streaming from Youtube_EN.pdf';
    }
  }

}
