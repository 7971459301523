<template>
  <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#000000" fill-rule="evenodd" clip-rule="evenodd" d="M34.364 14.1802V4.69619C34.364 3.69312 33.8371 2.78124 33.0123 2.2797C32.1647 1.80097 31.1338 1.82376 30.3319 2.34809L26.0708 5.12933V4.90136C26.0479 2.21131 23.9402 0 21.3286 0H4.71932C2.10766 0 0 2.21131 0 4.92416V17.8957C0 20.5857 2.10766 22.797 4.71932 22.797H5.65905V32.281C5.65905 33.2841 6.18596 34.196 7.0107 34.6975C7.85834 35.1762 8.88926 35.1534 9.69109 34.6291L13.9522 31.8479V32.0758C13.9522 34.7887 16.0599 37 18.6715 37H35.2808C37.8925 37 40.0001 34.7887 40.0001 32.0758V19.1043C40.0001 16.3915 37.8925 14.1802 35.2808 14.1802H34.364ZM5.65905 19.4459V18.8991C5.65905 17.8961 6.18596 16.9842 7.03361 16.5055C7.85834 16.0267 8.91217 16.0495 9.714 16.5738L13.9751 19.3551V19.1271C13.9751 16.4143 16.0828 14.203 18.6944 14.1802H22.8406V4.90136C22.8406 4.03508 22.1533 3.32837 21.3286 3.32837H4.71932C3.89458 3.32837 3.2073 4.03508 3.2073 4.90136V17.8729C3.2073 18.7392 3.89458 19.4459 4.71932 19.4459H5.65905ZM26.0479 14.1802H31.1338V5.76765L26.0479 9.07322V14.1802ZM35.3037 33.6716C36.1284 33.6716 36.8157 32.9649 36.8157 32.0986V19.1271C36.8157 18.2608 36.1284 17.5541 35.3037 17.5541H18.6944C17.8697 17.5541 17.1824 18.238 17.1824 19.1271V32.0986C17.1824 32.9649 17.8697 33.6716 18.6944 33.6716H35.3037ZM13.9751 27.904V23.2762L8.86635 19.9706V31.2323L13.9751 27.904Z"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconTwoCameras = Vue.extend({
  name: 'icon-two-cameras',
});
export default IconTwoCameras;
</script>
